import React, { useContext } from 'react'

import * as s from './styles'
import { Prescription } from 'types/Appointment'
import { ConnectivityContext } from 'hooks/ConnectivityContext'

interface IProps {
  prescriptions?: Prescription[]
}

const Prescriptions: React.FC<IProps> = ({ prescriptions }) => {
  const { isOnline } = useContext(ConnectivityContext)

  function openUrl(url: string) {
    window.open(url, '_blank')
  }

  return (
    <s.Prescriptions>
      {prescriptions.map((prescription, index) => (
        <s.Container
          key={prescription.id}
          onClick={() =>
            isOnline ? openUrl(prescription.prescriptionUrl) : null
          }
        >
          <s.Prescription type="font13">Documento {index + 1}</s.Prescription>
          <s.ArrowIcon name="arrow" />
        </s.Container>
      ))}
    </s.Prescriptions>
  )
}

export default Prescriptions
