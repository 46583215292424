import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'
import Button from 'components/Button'

export const Page = styled(Container)``

export const Header = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  padding-top: 32px;
  padding: 0px 20px 0px 20px;
  text-align: center
`

export const ImageContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

export const Subtitle = styled.div`
  ${({ theme }) => theme.fontTypes.h3_semibold};
  color: ${props => props.theme.colors.gray};
  margin-bottom: 20px;
`

export const Info = styled.div`
  ${({ theme }) => theme.fontTypes.p2_regular};
  color: ${props => props.theme.colors.gray3};
`

export const FooterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 36px;
`

export const ButtonStyled = styled(Button)`
  width: 100%;
  margin-top: 24px;
`

