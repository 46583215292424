import React, { useContext, useState, useEffect, useCallback } from 'react'
import { DatePicker } from '@material-ui/pickers'
import { useHistory, useParams } from 'react-router-dom'

import IconExporter from 'components/IconExporter'
import Text from 'components/Text'
import Button from 'components/Button'

import { CreateAttendanceContext } from 'hooks/CreateAttendanceContext'
import { useToast } from 'hooks/toast'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'

import * as s from '../styles'
import OfflineBar from 'components/OfflineBar'
import { ConnectivityContext } from 'hooks/ConnectivityContext'
import { Container } from 'styled-bootstrap-grid'
import { useMutate } from 'restful-react'
import { useQuery } from 'hooks/useQuery'

const nextTwoDay = new Date()
nextTwoDay.setDate(nextTwoDay.getDate() + 2)
const DateHour = () => {
  const { push, location } = useHistory()
  const { addToast } = useToast()
  const { setCurrentStep, formData, partnerId } = useContext(
    CreateAttendanceContext
  )
  const [selectedDate, setSelectedDate] = useState({
    value: '',
    formattedValue: ''
  })
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [timeSelected, setTimeSelected] = useState<{
    label: string
    value: string
  } | null>(null)
  const [availableHours, setAvailableHours] = useState([])
  const { isOnline } = useContext(ConnectivityContext)
  const query = useQuery()

  const originAppointmentId = query.get('originAppointmentId')
  const fromUnsuccessful = !!query.get('fromUnsuccessful')

  const { loading: availabilityLoading, mutate: getAvailability } = useMutate(
    'POST',
    'schedule/getavailability'
  )

  const { loading: loadingAppointment, mutate: createAppointment } = useMutate(
    'POST',
    'appointment'
  )

  const { loading: loadingCancelAppointment, mutate: cancelAppointment } = useMutate(
    'POST',
    `appointment/${originAppointmentId}/cancel`
  )

  const { loading: loadingUpdate, mutate: updateUnsuccessfulAppointment } = useMutate(
    'PATCH',
    `appointment/${originAppointmentId}/unsuccessful`
  )

  const getAvailableHours = useCallback(
    async date => {
      const data = await getAvailability({
        Speciality: formData.specialityId,
        Partnerid: partnerId,
        Date: date
      })
      setAvailableHours(
        data.map(available => ({
          value: available,
          label: available
        }))
      )
    },
    [addToast, partnerId, formData.specialityId]
  )

  useEffect(() => {
    setAvailableHours([])
    setTimeSelected(null)
    if (selectedDate.value) {
      getAvailableHours(selectedDate.value)
    }
  }, [selectedDate.value, getAvailableHours])

  async function createNewAppointment() {
    const response = await createAppointment({
      DateAppointment: selectedDate.value + 'T' + timeSelected.value,
      SpecialityId: formData.specialityId,
      Reason: formData.reason,
      Partnerid: partnerId,
      InterviewId: formData.InterviewId,
      Type: 6
    })
    addToast({
      description: 'Seu atendimento foi agendado.',
      type: 'success',
      title: 'Atendimento agendado com sucesso.'
    })
    setCurrentStep(null)
    handleCreatedAppointment(response)
  }

  function togglePicker() {
    setOpenDatePicker(prevState => !prevState)
  }

  function handleOnChangeDate(date) {
    setSelectedDate({
      value: date.format('YYYY-MM-DD'),
      formattedValue: date.format('DD/MM/YYYY')
    })
  }

  const handleCreatedAppointment = async (appointmentId: number) => {
    if (fromUnsuccessful) {
      await updateUnsuccessfulAppointment({
        isWhatsappFlowPending: true,
        rescheduleAppointmentId: appointmentId
      })
    }
    push('/dashboard')
  }

  function handleBackButton() {
    const { state }: any = location
    if (state) {
      if (state.usageOfInterviewAllowed) {
        setCurrentStep(null)
      } else {
        setCurrentStep('SYMPTOMS')
      }
    } else if (formData.InterviewId) {
      setCurrentStep(null)
    } else {
      setCurrentStep('SYMPTOMS')
    }
  }

  return (
    <>
      <s.Page>
        <Container>
          <s.Header>
            <BackIcon
              width={30}
              height={30}
              fill="gray"
              onClick={() => (isOnline ? handleBackButton() : null)}
            />
          </s.Header>
        </Container>
        <OfflineBar />
        <s.Content>
          <Text type="h2_semibold">Escolha uma data e horário </Text>
          <br />
          <Text type="p2_regular">
            Selecione abaixo o momento que deseja realizar seu atendimento por
            vídeo.
          </Text>
          <br />
          <br />
          <DatePicker
            open={openDatePicker}
            disableToolbar
            disablePast
            cancelLabel={
              <Text
                onClick={() => {
                  if (isOnline) {
                    togglePicker()
                    setSelectedDate({ value: null, formattedValue: null })
                  }
                }}
              >
                Limpar
              </Text>
            }
            okLabel={<Text onClick={togglePicker}>Salvar</Text>}
            format="DD/MM/YYYY"
            value={selectedDate.formattedValue}
            maxDate={nextTwoDay.toString()}
            placeholder="Selecione uma data"
            onChange={handleOnChangeDate}
            TextFieldComponent={() => (
              <s.InputDateAndHour>
                <input
                  readOnly
                  placeholder="Selecione uma data"
                  value={selectedDate.formattedValue}
                  onClick={togglePicker}
                />
                <IconExporter name="calendar" />
              </s.InputDateAndHour>
            )}
            DialogProps={{
              onBackdropClick: () => togglePicker()
            }}
          />
          <br />
          <s.Select
            classNamePrefix="react-select"
            options={availableHours}
            placeholder="Selecione um horário"
            isClearable
            isLoading={availabilityLoading}
            loadingMessage={() => <Text textAlign="center">Carregando...</Text>}
            noOptionsMessage={() => (
              <Text textAlign="center">
                Sem horários disponíveis para a data solicitada.
              </Text>
            )}
            isDisabled={!selectedDate.value}
            onChange={data => (isOnline ? setTimeSelected(data) : null)}
            value={timeSelected}
          />

          <s.BrazilTimezoneMessage type="p3_regular">
            (Considerando o horário oficial de Brasília)
          </s.BrazilTimezoneMessage>

          <s.ContainerButton>
            <Button
              color="green"
              disabled={!timeSelected || !isOnline}
              isLoading={loadingAppointment || loadingUpdate || loadingCancelAppointment}
              onClick={createNewAppointment}
            >
              Agendar atendimento
            </Button>
          </s.ContainerButton>
        </s.Content>
      </s.Page>
    </>
  )
}

export default DateHour
