import styled from 'styled-components'
import { Container as ContainerBTS } from 'styled-bootstrap-grid'

import ButtonDefault from 'components/Button'
import Text from 'components/Text'

export const Page = styled.div`
  position: relative;

  .carousel .control-dots {
    bottom: 100px;
  }

  .carousel .slide {
    .legend {
      bottom: 130px;
      background: transparent;
      opacity: unset;
    }
    img {
      height: 100vh;
      object-fit: cover;
    }
  }
`

export const Logo = styled.img`
  width: 150px;
  height: 100px;
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
`

export const CarouselSubtitle = styled(Text).attrs(() => ({
  color: 'white',
  textAlign: 'center'
}))`
  transition: all 0.5s ease-in-out;
  position: absolute;
  background: #000;
  padding: 10px 12px;
  font-size: initial;
  opacity: 0.25;
  transition: opacity 0.35s ease-in-out;
  bottom: 130px;
  background: transparent;
  opacity: unset;
`

export const Container = styled(ContainerBTS)`
  position: absolute;
  bottom: 25px;
`

export const Button = styled(ButtonDefault)`
  width: 100%;
  margin-bottom: 12px;
`

export const RegisterScreenLink = styled(Text)`
  span {
    text-decoration: underline;
  }
`
