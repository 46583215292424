import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import LocalStorage from 'helpers/localStorage'

import { Context } from 'hooks/BeforeAttendanceContext'
import Steps from './Steps'
import SpinnerLoading from 'components/SpinnerLoading'

const BeforeAttendance = () => {
  const history = useHistory()
  const { appointmentId } = useParams() as any;

  const { setCurrentStep } = useContext(Context)

  useEffect(() => {
    setCurrentStep('INFORM_CHECK_CAMERA')
  }, [setCurrentStep])

  if (!LocalStorage.getUserAuth().accessToken) {
    history.push(`/login/${appointmentId}`)
    return <SpinnerLoading />
  }

  return <Steps />
}

export default BeforeAttendance
