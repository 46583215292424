import React, { createContext, useState } from 'react'

interface IUserValidationInfo {
  names: []
  birthDates: []
  insuranceNumbers: []
}

export enum STEPS_ENUM {
  PHONE_NUMBER = 'PHONE_NUMBER',
  /*
  NAME_QUESTION = 'NAME_QUESTION',
  BIRTHDATE_QUESTION = 'BIRTHDATE_QUESTION',
  CARDNUMBER_QUESTION = 'CARDNUMBER_QUESTION',
  */
  EMAIL = 'EMAIL',
  CONFIRMATION_CODE = 'CONFIRMATION_CODE',
  DEFINE_PASSWORD = 'DEFINE_PASSWORD'
}

type STEP = keyof typeof STEPS_ENUM

type TypeFormData = {
  cpf: string
  code: string
  phoneNumber: string
  ddi: string
  email: string
  password: string
  // insuranceNumber: string
  // birthDate: string
  // name: string
}

interface ContextData {
  userValidationInfo: IUserValidationInfo
  setUserValidationInfo: (userInfo: IUserValidationInfo) => void
  currentStep: STEP
  setCurrentStep: (step: STEP) => void
  formData: TypeFormData
  setFormData: (formData: TypeFormData) => void
  attempts: number
  onIncreaseAttempts: () => void
}

const Context = createContext<ContextData>({} as ContextData)

const INITIAL_FORM_DATA = {
  cpf: '',
  code: '',
  phoneNumber: '',
  ddi: '',
  email: '',
  password: '',
  insuranceNumber: '',
  birthDate: '',
  name: ''
}

const AccountInfoProvider = ({ children }) => {
  const [userValidationInfo, setUserValidationInfo] = useState<
    IUserValidationInfo
  >(null)

  const [currentStep, setCurrentStep] = useState<STEP | null>(null)
  const [formData, setFormData] = useState<TypeFormData>(INITIAL_FORM_DATA)
  const [attempts, setAttempts] = useState<number>(0)

  function onIncreaseAttempts() {
    setAttempts(prev => prev + 1)
  }
  return (
    <Context.Provider
      value={{
        userValidationInfo,
        setUserValidationInfo,
        currentStep,
        setCurrentStep,
        formData,
        setFormData,
        attempts,
        onIncreaseAttempts
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Context, AccountInfoProvider }
