import styled from 'styled-components'
import { themeColors, fontTypes } from 'styles/theme'

interface IText {
  color: keyof typeof themeColors
  textAlign: 'left' | 'center' | 'right'
  type: keyof typeof fontTypes
}

export const TextStyle = styled.p<IText>`
  color: ${props => props.theme.colors[props.color]};
  text-align: ${props => props.textAlign};

  ${props => props.theme.fontTypes[props.type]}
`
