import React, {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
  useContext
} from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from 'styled-bootstrap-grid'

import { sendEvent } from 'helpers/telemetry'

import Text from 'components/Text'
import SpinnerLoading from 'components/SpinnerLoading'
import ModalMessage from 'components/ModalMessage'
import IconExporter from 'components/IconExporter'
import Appointment from 'types/Appointment'

import Appointments from './Appointments'
import AttendanceFeedback from './AttendanceFeedback'
import AttendanceUnsuccessful from './AttendanceUnsuccessful'

import * as s from './styles'
import OfflineBar from 'components/OfflineBar'
import { ConnectivityContext } from 'hooks/ConnectivityContext'
import { useGet } from 'restful-react'
import axiosInstance from 'services/api'

const Dashboard = () => {
  const history = useHistory()
  const [appointments, setAppointments] = useState<Appointment[] | []>([])
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
  const [isUnsuccessfulModalOpen, setIsUnsuccessfulModalOpen] = useState(false)
  const [userId, setUserId] = useState<string>('')

  const { isOnline } = useContext(ConnectivityContext)

  const { loading: loadingAppointments, data } = useGet('appointment/next')

  const {
    loading: loadingElegibility,
    refetch: checkElegibility,
    data: elegibilityData
  } = useGet('beneficiary/checkelegibility', { lazy: true })
  const { loading: loadingProfile, data: beneficiaryData } = useGet(
    'beneficiary/getdata'
  )

  const loading = loadingElegibility || loadingAppointments || loadingProfile

  const toggleFeedbackModal = useCallback(() => {
    setIsFeedbackModalOpen(prev => !prev)
  }, [])

  const toggleUnsuccessfulModal = useCallback(() => {
    setIsUnsuccessfulModalOpen(prev => !prev)
  }, [])

  useEffect(() => {
    if (data) {
      setAppointments(data)
    }
  }, [data])

  useEffect(() => {
    if (beneficiaryData) {
      setUserId(beneficiaryData.id)
    }
  }, [beneficiaryData])

  useEffect(() => {
    if (elegibilityData === true) {
      history.push('/criar-atendimento')
    }
    if (elegibilityData === false) {
      ModalMessage({
        title: 'Ops, não encontramos o seu benefício',
        message:
          'Isso pode ocorrer quando sua conta ou nossos serviços não estão mais disponíveis com o parceiro que está vinculado. Conheça nossas assinaturas ou outros parceiros disponíveis para continuar com o seu atendimento.',
        buttonText: 'Entrar em contato com nosso time',
        onClose: () =>
          window.open('https://docway.com.br/contato-empresa/', '_blank')
      })
    }
  }, [elegibilityData])

  function handleCreateAttendance() {
    sendEvent('Agendamento', 'agendar_video')
    checkElegibility()
  }

  useLayoutEffect(() => {
    const surveyPatientHasBeenFilled = localStorage.getItem(
      'surveyPatientHasBeenFilled'
    )

    const isAppointmentMatch = localStorage.getItem('isAppointmentMatch')

    const params = new URLSearchParams(history.location.search)
    if (
      params.get('appointmentId') &&
      params.get('feedbackModalOpen') &&
      surveyPatientHasBeenFilled === 'false' &&
      isAppointmentMatch === 'true'
    ) {
      toggleFeedbackModal()
    } else if (
      params.get('appointmentId') &&
      params.get('unsuccessfulModalOpen')
    ) {
      toggleUnsuccessfulModal()
    }
  }, [history.location.search, toggleFeedbackModal, toggleUnsuccessfulModal])

  return loading ? (
    <SpinnerLoading />
  ) : (
    <s.Container>
      <OfflineBar />
      {appointments.length > 0 && (
        <Appointments
          appointments={appointments}
          onChangeAppointments={setAppointments}
        />
      )}
      <s.SchedulingAttendanceContainer>
        <div>
          <s.SchedulingAttendance>
            <Container>
              <Text type="h3_semibold" color="white">
                Agende seu atendimento médico por vídeo
              </Text>
              <br />
              <Text color="white">
                Conversar com seu médico nunca foi tão rápido, seguro e
                confortável.
              </Text>
              <br />

              <s.Button
                variant="contained"
                color="white"
                onClick={() => (isOnline ? handleCreateAttendance() : null)}
              >
                <Text type="font2" color="green">
                  Agendar atendimento por vídeo
                </Text>
              </s.Button>
            </Container>
          </s.SchedulingAttendance>
          <s.LighterGreenBorder />
        </div>
      </s.SchedulingAttendanceContainer>
      <s.Modal
        open={isFeedbackModalOpen}
        onClose={() => {
          setIsFeedbackModalOpen(false)
        }}
      >
        <AttendanceFeedback onCloseModal={toggleFeedbackModal} />
      </s.Modal>
      <s.Modal
        open={isUnsuccessfulModalOpen}
        onClose={() => {
          setIsUnsuccessfulModalOpen(false)
        }}
      >
        <AttendanceUnsuccessful onCloseModal={toggleUnsuccessfulModal} />
      </s.Modal>
    </s.Container>
  )
}

export default Dashboard
