export const handleMediaErrors = err => {
  let title
  let message
  switch (err.name) {
    case 'NotAllowedError':
      title = 'Permissão negada'
      message =
        'Você negou anteriormente a permissão para utilizar a câmera. Abra as configurações do seu dispositivo para autorizar o acesso ao seu navegador.'
      break
    case 'AbortError':
      title = 'Ops! Ocorreu um erro'
      message =
        'Achamos um problema que impede o uso da câmera neste dispositivo. Por favor, tente solicitar o atendimento em outro dispositivo com câmera.'
      break
    case 'NotFoundError':
      title = 'Mídia indisponível'
      message =
        'Não conseguimos acessar a sua câmera. Por favor, verifique se está sendo usada em algum outro aplicativo ou solicite o atendimento em outro dispositivo.'
      break
    case 'NotReadableError':
      title = 'Ops! Erro inesperado'
      message =
        'Achamos um problema que impede o uso da câmera neste dispositivo. Por favor, tente solicitar o atendimento em outro dispositivo com câmera.'
      break
    case 'OverconstrainedError':
      title = 'Mídia não reconhecida'
      message =
        'Não conseguimos acessar a sua câmera. Por favor, tente solicitar o atendimento em outro dispositivo com câmera.'
      break
    case 'SecurityError':
      title = 'Acesso negado'
      message =
        'Você negou anteriormente a permissão para utilizar a câmera. Abra as configurações do seu dispositivo para autorizar o acesso ao seu navegador.'
      break
    case 'TypeError':
      title = 'Problemas com o navegador'
      message =
        'Achamos um problema que impede o uso da câmera neste navegador. Por favor, tente solicitar o atendimento em outro navegador.'
      break
  }

  return {
    title,
    message
  }
}
