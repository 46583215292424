import React from 'react'
import ReactDOM from 'react-dom'

import App from 'App'

import 'moment/locale/pt-br'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

/**
 * Habilitando custom web-components
 */
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace JSX {
    interface IntrinsicElements {
      [key: string]: any
    }
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
