import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'
import ButtonDefault from 'components/Button'
import Text from 'components/Text'
import TextArea from 'components/TextArea'

export const Page = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  width: 100vw;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: flex-start;
  margin-bottom: 32px;
`

export const Message = styled(Text)`
  margin-top: 16px;
`

export const Field = styled(TextArea)`
  height: 190px;
  margin-top: 32px;
`

export const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 32px;
`

export const Button = styled(ButtonDefault)`
  width: 100%;
`
