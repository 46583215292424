import React from 'react'
import { ThemeProvider } from 'styled-components'
import theme from 'styles/theme'

import * as s from './styles'

import SweetAlert from '@sweetalert/with-react'

interface IProps {
  title: string
  message: string
  onClose?: () => void
  buttonText: string
  hasSupportText?: boolean
}

const ModalMessage: React.FC<IProps> = ({
  title,
  message,
  onClose,
  buttonText,
  hasSupportText = false
}) => {
  function redirectToFAQ() {
    window.open('https://docwayhelp.zendesk.com/hc/pt-br', '_blank')
  }

  return SweetAlert({
    title: title,
    text: message,
    buttons: false,
    content: (
      <ThemeProvider theme={theme}>
        {hasSupportText ? (
          <s.Support>
            Precisa de ajuda? <a onClick={redirectToFAQ}>Fale com o suporte</a>
            <s.Button
              onClick={() => {
                if (onClose) onClose()

                SweetAlert.close()
              }}
            >
              {buttonText}
            </s.Button>
          </s.Support>
        ) : (
          <s.Button
            onClick={() => {
              if (onClose) onClose()

              SweetAlert.close()
            }}
          >
            {buttonText}
          </s.Button>
        )}
      </ThemeProvider>
    )
  })
}

export default ModalMessage
