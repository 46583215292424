import React, { useEffect, useRef } from 'react'
import WaveSurfer from 'wavesurfer.js'
import MicrophonePlugin from 'wavesurfer.js/src/plugin/microphone/index.js'

import * as s from './styles'

interface IProps {
  handleEnableMicrophone: () => void
  handleErrorOnEnableMicrophone: (codeError: string) => void
}

const Waveform: React.FC<IProps> = ({
  handleEnableMicrophone,
  handleErrorOnEnableMicrophone
}) => {
  const wavesurfer = useRef<WaveSurfer>(null)
  const waveRef = useRef(null)

  const rootMeanSquare = samples => {
    const sumSq = samples.reduce((sumSq, sample) => sumSq + sample * sample, 0)
    return Math.sqrt(sumSq / samples.length)
  }

  useEffect(() => {
    if (handleEnableMicrophone && handleErrorOnEnableMicrophone) {
      wavesurfer.current = WaveSurfer.create({
        barWidth: 4,
        barGap: 16,
        barRadius: 6,
        barMinHeight: 20,
        container: `#${waveRef?.current.id}`,
        backend: 'WebAudio',
        height: 150,
        responsive: true,
        waveColor: '#FDFDFD',
        cursorColor: 'transparent',
        plugins: [MicrophonePlugin.create()]
      })

      wavesurfer?.current.microphone.on('deviceReady', function (stream) {
        const AudioContext =
          window.AudioContext || (window as any).webkitAudioContext

        const audioContext = new AudioContext()
        if (!audioContext) {
          return
        }

        const source = audioContext.createMediaStreamSource(stream)

        const analyser = audioContext.createAnalyser()
        analyser.fftSize = 1024
        analyser.smoothTimeConstant = 0.3

        analyser.minDecibels = -90
        analyser.maxDecibels = -10

        const processor = audioContext.createScriptProcessor()

        source.connect(analyser)
        analyser.connect(processor)

        processor.connect(audioContext.destination)

        let level = null

        processor.onaudioprocess = () => {
          const samples = new Uint8Array(analyser.frequencyBinCount)
          analyser.getByteFrequencyData(samples)

          const rms = rootMeanSquare(samples)
          const log2Rms = rms && Math.log2(rms)

          const newLevel = Math.ceil((10 * log2Rms) / 8)

          if (level !== newLevel) {
            level = newLevel

            if (level > 5) {
              processor.disconnect()
              handleEnableMicrophone()
            }
          }
        }
      })
      wavesurfer?.current.microphone.on('deviceError', function (
        codeError: string
      ) {
        handleErrorOnEnableMicrophone(codeError)
      })

      wavesurfer?.current.microphone.start()

      return () => {
        // wavesurfer?.current?.microphone.stop()
      }
    }
  }, [handleEnableMicrophone, handleErrorOnEnableMicrophone])

  return (
    <s.WaveformContainer>
      <s.Wave id="waveform" ref={waveRef} />
      <s.Icon name="speakerphone" />
    </s.WaveformContainer>
  )
}

export default Waveform
