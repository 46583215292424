import React from 'react'
import Text from 'components/Text'
import { useHistory } from 'react-router-dom'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'

import * as s from './styles'

const LegalInformations = () => {
  const history = useHistory()

  function handlePrivatePolicy() {
    window.open('https://docway.com.br/politica-de-privacidade/', '_blank')
  }

  function handleTerms() {
    window.open('https://docway.com.br/termos-de-uso-paciente/', '_blank')
  }

  function handleTermsOfConsent() {
    window.open(
      'https://docway.com.br/termo-de-consentimento-paciente/',
      '_blank'
    )
  }

  function handleCancelPolicy() {
    window.open('https://docway.com.br/regras-para-cancelamento/', '_blank')
  }

  function handleMyData() {
    history.push('/perfil/meus-dados')
  }

  function handleBack() {
    history.push('/perfil')
  }

  return (
    <s.Page>
      <s.Header>
        <BackIcon
          width={30}
          height={30}
          fill="gray"
          onClick={() => handleBack()}
        />
      </s.Header>
      <s.Body color="grayLight">
        <div onClick={() => handleTerms()}>
          <Text type={'font2'}>Termos de Uso </Text>
        </div>

        <div onClick={() => handleTermsOfConsent()}>
          <Text type={'font2'}>Termo de Consentimento </Text>
        </div>

        <div onClick={() => handlePrivatePolicy()}>
          <Text type={'font2'}>Política de privacidade</Text>
        </div>

        <div onClick={() => handleCancelPolicy()}>
          <Text type={'font2'}>Política de cancelamento</Text>
        </div>

        <div onClick={() => handleMyData()}>
          <Text type={'font2'}>Proteção de Dados (LGPD)</Text>
        </div>
      </s.Body>
    </s.Page>
  )
}

export default LegalInformations
