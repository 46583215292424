import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import Input from 'components/Input'
import Text from 'components/Text'
import Button from 'components/Button'

import axios from 'services/api'
import localStorage from 'helpers/localStorage'
import { Context } from 'hooks/AccountValidation'
import { useToast } from 'hooks/toast'

import IconExporter from 'components/IconExporter'

import WaitScreen from '../WaitScreen'
import * as s from './styles'

const DefinePassword = () => {
  const { setCurrentStep, formData, onIncreaseAttempts } = useContext(Context)
  const history = useHistory()
  const { addToast } = useToast()
  const [password, setPassword] = useState({
    value: '',
    touched: false
  })

  const [confirmPassword, setConfirmPassword] = useState({
    value: '',
    touched: false
  })

  const [loading, setLoading] = useState(false)
  const [invalidateRegister, setInvalidateUser] = useState(false)

  function redirectToTerms() {
    window.open('https://docway.com.br/termos-de-uso-paciente/', '_blank')
  }

  function redirectToTermsOfConsent() {
    window.open(
      'https://docway.com.br/termo-de-consentimento-paciente/',
      '_blank'
    )
  }

  async function handleLogin() {
    setLoading(true)
    try {
      const { data } = await axios.post('/user/connect/token', {
        username: formData.email,
        password: password.value
      })

      localStorage.setUserAuth({
        accessToken: data.token,
        refreshToken: data.refresh_token,
        expiresIn: data.expires_in,
        tokenType: data.token_type
      })

      addToast({
        description: 'Você será redirecionado para a área logada.',
        type: 'success',
        title: 'Conta criada com sucesso.'
      })

      setTimeout(() => {
        history.push('/dashboard')
      }, 3000)
    } catch (error) {
      addToast({
        description: 'Você será redirecionado para a área de login.',
        type: 'success',
        title: 'Conta criada com successo.'
      })
      setTimeout(() => {
        history.push('/login')
      }, 5000)
    } finally {
      setLoading(false)
    }
  }

  async function handleRegisterUser() {
    setLoading(true)
    const {
      // name,
      code,
      cpf,
      phoneNumber,
      ddi,
      email,
      // insuranceNumber,
      // birthDate
    } = formData
    try {
      await axios.post('/user/register', {
        code,
        // name,
        cpf,
        phoneNumber,
        ddi,
        email,
        password: password.value,
        // insuranceNumber,
        // birthDate
      })
      handleLogin()
    } catch (error) {
      setLoading(false)
      const { code } = error.data[0]
      if (code === '22') {
        setInvalidateUser(true)
        onIncreaseAttempts()
      } else if (code === '23') {
        setCurrentStep('CONFIRMATION_CODE')
        addToast({
          title: 'Falha ao validar o código.',
          description: 'Envie um novo código e tente novamente.',
          type: 'error'
        })
      } else {
        addToast({
          title: 'Falha ao executar operação.',
          description: 'Tente novamente.',
          type: 'error'
        })
      }
    }
  }

  function isValidPassword() {
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})'
    )

    return strongRegex.test(password.value)
  }

  if (invalidateRegister) {
    return <WaitScreen />
  }

  return (
    <s.Page>
      <s.Header>
        <s.ContainerTop>
          <IconExporter
            name="arrowleft"
            width={30}
            height={30}
            fill="white"
            onClick={() => {
              setCurrentStep('EMAIL')
            }}
          />
        </s.ContainerTop>
        <s.HeaderDescriptionContainer>
          <Text color="white" type="h2_semibold">
            Crie sua senha de acesso
          </Text>
          <br />
          <Text color="white" type="font7">
            No mínimo 10 caracteres, sendo: <br />
            • Caractere alfabético maiúsculo e minúsculo <br />
            • Caractere numérico <br />• Caractere especial
          </Text>
        </s.HeaderDescriptionContainer>
      </s.Header>
      <s.Content>
        <Input
          autoFocus
          type="password"
          label="Senha"
          value={password.value}
          hasError={!isValidPassword() && password.touched}
          errorMessage="A senha deve conter os requisitos necessários."
          onChange={({ target }) => {
            setPassword({ ...password, value: target.value })
          }}
          onBlur={() => {
            setPassword({ ...password, touched: true })
          }}
        />
        <Input
          label="Confirme a senha"
          type="password"
          value={confirmPassword.value}
          hasError={
            confirmPassword.touched && confirmPassword.value !== password.value
          }
          errorMessage="As senhas devem ser iguais."
          onChange={({ target }) => {
            setConfirmPassword({
              ...confirmPassword,
              value: target.value,
              touched: true
            })
          }}
        />
        <s.FooterContainer>
          <Text type="font8" color="gray2" textAlign="center">
            Ao cadastrar-se, você concorda com os
            <u onClick={redirectToTerms}>Termos de Uso</u> e com os
            <u onClick={redirectToTermsOfConsent}>Termos de Consentimento</u>
          </Text>
          <Button
            color="green"
            disabled={
              !isValidPassword() || confirmPassword.value !== password.value
            }
            onClick={handleRegisterUser}
            isLoading={loading}
          >
            <Text type="p2_regular" color="green">
              Cadastrar
            </Text>
          </Button>
        </s.FooterContainer>
      </s.Content>
    </s.Page>
  )
}

export default DefinePassword
