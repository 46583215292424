import React, { createContext, useState } from 'react'

export enum STEPS_ENUM {
  SYMPTOMS = 'SYMPTOMS',
  DATE_HOUR = 'DATE_HOUR'
}

type STEP = keyof typeof STEPS_ENUM

type TypeFormData = {
  specialityId: string
  partnerId: string
  reason: string
  InterviewId?: number | undefined
}

interface ContextData {
  currentStep: STEP
  setCurrentStep: (step: STEP) => void
  formData: TypeFormData
  setFormData: React.Dispatch<React.SetStateAction<TypeFormData>>
  partnerId: string
  setPartnerId: (partnerId: string) => void
}

const INITIAL_FORM_DATA = {
  specialityId: '',
  partnerId: '',
  reason: ''
}

const CreateAttendanceContext = createContext<ContextData>({} as ContextData)

const CreateAttendanceProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<STEP | null>(null)
  const [partnerId, setPartnerId] = useState<string>('')
  const [formData, setFormData] = useState<TypeFormData>(INITIAL_FORM_DATA)
  return (
    <CreateAttendanceContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        formData,
        setFormData,
        partnerId,
        setPartnerId
      }}
    >
      {children}
    </CreateAttendanceContext.Provider>
  )
}

export { CreateAttendanceContext, CreateAttendanceProvider }
