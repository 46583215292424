import styled, { css } from 'styled-components'
import Participant from '../Participant'

export const Container = styled.div`
  display: block;
  height: calc(100vh - 64px);
  background-color: rgba(112, 112, 112, 0.2);
`

export const Content = styled.div`
  position: relative;
  height: 100%;
`

export const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e0e0e0;
  padding: 30px;
`

export const MiniParticipant = styled(Participant)`
  video {
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 100px;
    object-fit: cover;
    position: absolute;
    right: 16px;
    top: 24px;
    width: 80px;
  }
`

export const FullVideo = styled(Participant)`
  height: 100%;
  video {
    width: 100vw;
    object-fit: cover;
    height: 100%;
  }
`

export const Controls = styled.div`
  position: absolute;
  bottom: 40px;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

interface ErrorBoxProps {
  visible: boolean
}

export const ErrorBox = styled.div<ErrorBoxProps>`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 40px 32px 28px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  svg {
    position: absolute;
    top: 12px;
    right: 12px;
  }
`

const commonStylesButton = css`
  border-radius: 50%;
  border: none;
  outline: none;
`

export const SmallerButton = styled.button`
  ${commonStylesButton};
  height: 44px;
  width: 44px;
  background-color: ${({ theme }) => theme.colors.white};
`

export const HangoutButton = styled.button`
  ${commonStylesButton};
  height: 60px;
  width: 60px;
  background-color: ${({ theme }) => theme.colors.red};
`

export const ReconnectiveContainer = styled.div`
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #000;
  padding: 0 16px;
`
export const ReconnectiveButton = styled.button`
  width: 100%;
  height: 40px;
  margin: 40px 0 0;
  padding: 12px 39.8px 12px 41.2px;
  border: solid 2px #59bec9;
  border-radius: 10px;
  background-color: transparent;
  color: #59bec9;
`
export const Title = styled.p`
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`

export const SubTitle = styled.p`
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
`
