import React, { useState, useContext, useEffect } from 'react'
import { validate } from 'gerador-validador-cpf'
import { useHistory } from 'react-router-dom'
import SweetAlert from '@sweetalert/with-react'

import { Context } from 'hooks/AccountValidation'

import Input from 'components/Input'
import ButtonNext from 'components/ButtonNext'
import ModalMessage from 'components/ModalMessage'

import axios from 'services/api'

import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'

import Steps from './Steps'
import * as s from './styles'
import { useToast } from 'hooks/toast'
import { ThemeProvider } from 'styled-components'
import theme from 'styles/theme'
import Text from 'components/Text'

const Register = () => {
  const [cpf, setCpf] = useState({
    value: '',
    isValid: false,
    touched: false
  })

  function redirectToFAQ() {
    window.open('https://docwayhelp.zendesk.com/hc/pt-br', '_blank')
  }

  const [isLoading, setIsLoading] = useState(false)

  const {
    setUserValidationInfo,
    currentStep,
    setCurrentStep,
    formData,
    setFormData
  } = useContext(Context)
  const { addToast } = useToast()
  const history = useHistory()

  useEffect(() => {
    setCpf(prevState => ({
      ...prevState,
      isValid: validate(prevState.value)
    }))
  }, [cpf.value])

  function redirectToNoCpfHelpScreen() {
    SweetAlert({
      buttons: false,
      content: (
        <ThemeProvider theme={theme}>
          <div>
            <s.CardContent>
              <Text type="h3_semibold" textAlign="center">
                Não tem CPF?
              </Text>
              <br />
              <Text type="p2_regular" color="gray2" textAlign="center">
                Provavelmente o seu perfil está vinculado ao titular do seu
                beneficio ou plano de saúde. Através do CPF do titular é
                possivel solicitar atendimento para todos os seus dependentes.
                Caso continue tendo problemas, entre em contato com nosso
                suporte.
              </Text>
              <br />
              <Text type="font8" color="gray2" textAlign="center">
                Ficou com alguma dúvida?{' '}
                <u onClick={redirectToFAQ}>Acesse nosso FAQ</u>
              </Text>
            </s.CardContent>
            <s.ButtonContainer>
              <Text
                color="green"
                onClick={() => {
                  SweetAlert.close()
                }}
              >
                Entendi!
              </Text>
            </s.ButtonContainer>
          </div>
        </ThemeProvider>
      )
    })
  }

  async function validateCPF() {
    setIsLoading(true)
    try {
      const { data } = await axios.post('/user/Validation', {
        cpf: cpf.value
      })
      setUserValidationInfo(data)
      setCurrentStep('PHONE_NUMBER')
      setFormData({ ...formData, cpf: cpf.value })
    } catch (error) {
      let errorDescription = 'Um erro ocorreu. Tente novamente.'
      let errorTitle = 'Falha na requisição.'

      if (error.data && error.status === 400) {
        if (error.data[0].code === '-100') {
          ModalMessage({
            title: 'Acesso bloqueado :(',
            message:
              'Ops, por conta da sua idade, o acesso ao aplicativo infelizmente não está autorizado',
            buttonText: 'Voltar'
          })
          return
        } else {
          errorDescription = error.data[0].message
          errorTitle = 'Ops!'
        }
      }

      addToast({
        type: 'error',
        title: errorTitle,
        description: errorDescription
      })
    } finally {
      setIsLoading(false)
    }
  }

  return currentStep ? (
    <Steps />
  ) : (
    <s.Page>
      <s.Header>
        <s.ContainerTop>
          <BackIcon
            width={30}
            height={30}
            fill="white"
            onClick={() => history.push('/')}
          />
        </s.ContainerTop>
        <s.HeaderTitle color="white" type="h2_semibold">
          Qual o seu CPF?
        </s.HeaderTitle>
      </s.Header>
      <s.Content>
        <Input
          autoFocus
          label="CPF"
          mask="999.999.999-99"
          value={cpf.value}
          onChange={({ target }) =>
            setCpf({
              ...cpf,
              value: target.value,
              isValid: validate(cpf.value)
            })
          }
          onKeyPress={() => {
            setCpf({
              ...cpf,
              touched: true
            })
          }}
          hasError={!cpf.isValid && cpf.touched}
          errorMessage="Digite um CPF válido."
        />

        <s.FooterContainer>
          <s.RequestCallText type="p4_regular">
            Você não tem CPF? <br />
            <span onClick={redirectToNoCpfHelpScreen}>
              Entenda como solicitar um atendimento
            </span>
          </s.RequestCallText>
          <ButtonNext
            isLoading={isLoading}
            disabled={!cpf.isValid}
            onClick={validateCPF}
          />
        </s.FooterContainer>
      </s.Content>
    </s.Page>
  )
}

export default Register
