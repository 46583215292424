import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'

import Text from 'components/Text'

export const Page = styled.div`
  height: 100vh;
  width: 100vw;
`

export const Header = styled(Container)`
  background: ${({ theme }) => theme.colors.green};
  width: 100%;
  height: 58%;
  position: relative;
`

export const HeaderTitle = styled(Text)`
  position: absolute;
  bottom: 120px;
`

export const ContainerTop = styled.div`
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 42%;
  padding-top: 40px;
`

export const FooterContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 32px;
`

export const RequestCallText = styled(Text)`
  span {
    text-decoration: underline;
  }
`

export const CardContent = styled.div`
  padding: 20px 16px;
`

export const ButtonContainer = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
`
