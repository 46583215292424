import React, { useContext } from 'react'

import { Context, STEPS_ENUM } from 'hooks/BeforeAttendanceContext'

import InformCheckCamera from './InformCheckCamera'
import CheckCamera from './CheckCamera'
import InformCheckMicrophone from './InformCheckMicrophone'
import CheckMicrophone from './CheckMicrophone'
import Checklist from './Checklist'

type STEPPER = { [key in STEPS_ENUM]: React.ReactElement }

const steps: STEPPER = {
  INFORM_CHECK_CAMERA: <InformCheckCamera />,
  CHECK_CAMERA: <CheckCamera />,
  INFORM_CHECK_MICROPHONE: <InformCheckMicrophone />,
  CHECK_MICROPHONE: <CheckMicrophone />,
  CHECKLIST: <Checklist />
}

const Steps = () => {
  const { currentStep } = useContext(Context)
  return steps[currentStep]
}

export default Steps
