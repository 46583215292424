import React from 'react'
import { themeColors } from 'styles/theme'

import SpinnerLoading from 'components/SpinnerLoading'

import * as s from './styles'

interface IProps {
  color?: keyof typeof themeColors
  isLoading?: boolean
}

const ButtonNext: React.FC<
  IProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ color = 'green', isLoading = false, ...rest }) => (
  <s.Button color={color} {...rest}>
    {isLoading ? (
      <SpinnerLoading color={color} />
    ) : (
      <s.ArrowRight color={color} disabled={rest.disabled} />
    )}
  </s.Button>
)

export default ButtonNext
