import React, { useState, useEffect, useRef, useContext, FormEvent } from 'react'
import Text from 'components/Text'
import Input from 'components/Input'
import { useHistory } from 'react-router-dom'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'
import moment from 'moment'

import PhoneNumber from 'components/PhoneNumber'

import * as s from './styles'
import { useToast } from 'hooks/toast'
import SpinnerLoading from 'components/SpinnerLoading'
import OfflineBar from 'components/OfflineBar'
import { ConnectivityContext } from 'hooks/ConnectivityContext'
import { useGet, useMutate } from 'restful-react'

const EditProfile = ({isValidation}) => {
  const history = useHistory()
  const { addToast } = useToast()
  const [ddi, setDdi] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [profilePicture, setProfilePicture] = useState('')
  const [profilePictureContent, setProfilePictureContent] = useState('')
  const fileUploader = useRef(null)

  const [userData, setUserData] = useState({
    value: undefined,
    isValid: false
  })

  const { isOnline } = useContext(ConnectivityContext)

  const { loading: loadingProfile, data } = useGet('beneficiary/getdata')

  const { loading: loadingUpdateProfile, mutate: updateProfile } = useMutate(
    'PATCH',
    'beneficiary/updatedata'
  )

  const loading = loadingProfile || loadingUpdateProfile

  useEffect(() => {
    if (data) {
      setBirthDate(moment(data.birthDate.dateOfBirth).format(
        'DD/MM/YYYY'
      ))
      setPhoneNumber(data.phoneNumber.number)
      setDdi(data.phoneNumber.ddi)
      setProfilePicture(data.profilePicture.pictureUrl)
      setUserData({
        value: data,
        isValid: isUserDataValid(data)
      })
    }
  }, [data])

  function handleBack() {
    history.push(isValidation ? '/dashboard' : '/perfil')
    window.location.reload()
  }

  const validateBirthDate = (str:string) => {
    const splited = str.split('/');
    const validInput = !isNaN(Number(splited[0])) && !isNaN(Number(splited[1])) && !isNaN(Number(splited[2]));
    const date = moment(str, "DD/MM/YYYY");
    const isValid = validInput && date.isValid() && date.isAfter(moment().add('years', -120)) && date.isBefore(moment());

    return {
      valid: isValid,
      message: isValid ? undefined : 'Informe uma data válida.'
    }
  }

  const convertStringToDate = (str:string) => {
    const splited = str.split('/');

    return `${splited[2]}-${splited[1]}-${splited[0]} 00:00:00.000`
  }

  async function updateUserData() {
    const bodyFormData = new FormData()
    bodyFormData.append('profilePicture', profilePictureContent)
    bodyFormData.append('phoneNumber', phoneNumber)
    bodyFormData.append('DateOfBirth', convertStringToDate(birthDate))
    bodyFormData.append('ddi', ddi)

    const data = await updateProfile(bodyFormData)

    addToast({
      description: 'Seus dados foram atualizados',
      type: 'success',
      title: 'Eba!'
    })
    if (isValidation && isUserDataValid(data)) {
      history.push('/criar-atendimento')
    } else {
      setUserData({
        value: data,
        isValid: isUserDataValid(data)
      });
    }
  }

  function isUserDataValid(data) {
    return data !== undefined
  }

  function dataHasChanged() {
    return (
      userData.value.phoneNumber.number !== phoneNumber ||
      profilePictureContent !== ''
    )
  }

  function changeProfileImage() {
    fileUploader.current.click()
  }

  function handleSelectedImage(event) {
    setProfilePictureContent(event.target.files[0])

    const reader = new FileReader()
    reader.onload = e => {
      setProfilePicture(e.target.result as string)
    }
    reader.readAsDataURL(event.target.files[0])
  }

  return !userData.isValid ? (
    <SpinnerLoading color="green" />
  ) : (
    <s.Page>
      <s.Header>
        <BackIcon
          width={30}
          height={30}
          fill="gray"
          onClick={() => handleBack()}
        />
      </s.Header>

      <OfflineBar />

      <s.ProfileImageContainer color={'gray2'}>
        <s.SubTitle color={'gray'}>
          <Text type={'font9'}>{isValidation ? 'Antes de agendar, é necessário a confirmação dos seus dados' : 'Me conte sobre você'}</Text>
        </s.SubTitle>

        <div>
          <s.ImageContainer
            width="100px"
            height="100px"
            radious="50px"
            backgroundColor="green"
          >
            <div>
              {profilePicture ? (
                <img
                  src={profilePicture}
                  alt="profile"
                  onClick={changeProfileImage}
                ></img>
              ) : (
                <div onClick={changeProfileImage}>
                  <s.People />
                </div>
              )}
              <input
                type="file"
                id="file"
                ref={fileUploader}
                style={{ display: 'none' }}
                onChange={handleSelectedImage}
              />
            </div>
          </s.ImageContainer>
          <Text type={'font10'}>
            Quer trocar a foto? <br />
            Edite aqui!
          </Text>
        </div>
      </s.ProfileImageContainer>

      <s.DataContainer
        borderColor="grayLight"
        backgroundColor="white2"
        type={'font1'}
      >
        <Input
          label="Nome"
          value={userData.value.name.firstName}
          alwaysShowMask={false}
          disabled={true}
        />

        <Input
          label="Sobrenome"
          value={userData.value.name.lastName}
          alwaysShowMask={false}
          disabled={true}
        />

        <Input
          label="CPF"
          mask="999.999.999-99"
          value={userData.value.cpf.number}
          disabled={true}
        />

        <Input
          label="Data de nascimento"
          mask="99/99/9999"
          alwaysShowMask={false}
          value={birthDate}
          disabled={!isValidation}
          onChange={(e: FormEvent) => isValidation ? setBirthDate((e.target as HTMLInputElement).value) : null}
          hasError={isValidation && !validateBirthDate(birthDate)?.valid}
          errorMessage={validateBirthDate(birthDate)?.message}
        />
        {userData.value.gender !== -1 && (
          <s.RadioContainer type={'font5'} color="green">
            <p>Sexo</p>

            <div>
              <label htmlFor="Feminino">Feminino</label>
              <input
                type="radio"
                name="sex"
                defaultChecked={userData.value.gender === 1}
                disabled={true}
              />
            </div>

            <div>
              <label htmlFor="Masculino">Masculino</label>
              <input
                type="radio"
                name="sex"
                defaultChecked={userData.value.gender === 0}
                disabled={true}
              />
            </div>
          </s.RadioContainer>
        )}
        <Input
          label="E-mail"
          alwaysShowMask={false}
          value={userData.value.email.emailAddress}
          disabled={true}
        />

        <s.PhoneNumberContainer>
          <PhoneNumber
            ddi={ddi}
            onChangeDdi={setDdi}
            phoneNumber={phoneNumber}
            onChangePhoneNumber={value => {
              setPhoneNumber(value)
            }}
          />
        </s.PhoneNumberContainer>
      </s.DataContainer>

      <s.Footer>
        <s.Button
          color="green"
          disabled={!isValidation ? (!dataHasChanged() || !isOnline)  : !validateBirthDate(birthDate)?.valid}
          isLoading={loading}
          onClick={updateUserData}
        >
          {`Salvar${isValidation ? ' e continuar agendando' : ''}`}
        </s.Button>
      </s.Footer>
    </s.Page>
  )
}

export default EditProfile
