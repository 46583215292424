import styled from 'styled-components'

export const NoVideo = styled.div`
    height: 100%;
    width: 100%;
    background-color: black;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const DoctorPhoto = styled.img`
    width: 112px;
    height: 112px;
    border-radius: 50%;
`