import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import IconExporter from 'components/IconExporter'
import Button from 'components/Button'
import Text from 'components/Text'
import Checkbox from 'components/Checkbox'
import SpinnerLoading from 'components/SpinnerLoading';
import * as s from './styles'
import { Context } from 'hooks/BeforeAttendanceContext';
import axios from 'services/api'
import { useGet } from 'restful-react';

enum STEPS {
  LOADING = 'loading',
  VIDEO = 'video',
  CHECKLIST = 'checklist',
}
const INITIAL_TOPICS: any[] = [
  {id: 1, text: 'Estou em um ambiente privativo', checked: false },
  {id: 2, text: 'Não estou dirigindo', checked: false },
  {id: 3, text: 'Estou usando roupas adequadas', checked: false },
  {id: 4, text: 'Serei respeitoso com o profissional', checked: false }
]
const Checklist = () => {
  const [disabled, setDisabled] = React.useState(true);
  const [step, setStep] = React.useState(STEPS.LOADING);
  const [topics, setTopics] = React.useState(INITIAL_TOPICS);

  const history = useHistory()
  const { appointmentId } = useParams() as any;
  const { setCurrentStep } = React.useContext(Context)

  const { loading: loadingProfile, data } = useGet('beneficiary/getdata')

  const handleNext = () => {
    switch (step) {
      case STEPS.VIDEO:
        setStep(STEPS.CHECKLIST);
        break;
      case STEPS.CHECKLIST:
        // SEGUIR PARA A TELA DE CONSULTA
        history.push(`/attendance/${appointmentId}`)
        break;
    }
  }

  const listener = () => {
    // ENVIAR INFORMAÇÂO DE POSTAGEM DO VIDEO
    axios.post(`user/${data.id}/educationalvideo`);
    setDisabled(false)
  };

  React.useEffect(() => {
    setDisabled(true);
    const videoPlayer = document.getElementById('educational-video')

    switch (step) {
      case STEPS.VIDEO:
        videoPlayer?.addEventListener('video-player-ended', listener);
        break;
      case STEPS.CHECKLIST:
        break;
    }
  }, [step]);

  React.useEffect(() => {
   if (data?.id) {
    axios.get(`user/${data.id}/educationalvideo`)
      .then(response => {
        // Inicializa a página 
        setStep((!response.data) ? STEPS.VIDEO : STEPS.CHECKLIST);
      })
      .catch(() => {
        setStep(STEPS.VIDEO);
      });
      // VERIFICAR SE A CONSULTA É PEDIÁTRICA E INSERIR A ULTIMA PERGUNTA
      if (!data.birthDate?.age || data.birthDate?.age <= 12) {
        const newTopics = [...topics];
        newTopics.push({id: 5, text: 'O paciente menor de idade estará presente na consulta', checked: false })
        setTopics(newTopics);
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (step === STEPS.CHECKLIST) {
      setDisabled(!topics.every(item => item.checked));
    }
  }, [topics]);

  const handleSelected = (topic: any) => {
    const index = topics.findIndex(item => item.id === topic.id);
    const newTopics = [...topics];
    newTopics[index] = {...newTopics[index], checked: !newTopics[index].checked}
    setTopics(newTopics);
  }

  return (
    <s.Page>
      <div>
        <s.Header>
          <IconExporter
            name="arrowleft"
            width={30}
            height={30}
            color="gray"
            onClick={() => () => setCurrentStep('INFORM_CHECK_MICROPHONE')}
          />
        </s.Header>

        {step === STEPS.VIDEO ? (
          <React.Fragment>
            <s.Title type="h3_semibold">
              Antes de iniciar a consulta, temos um recado importante para você.
            </s.Title>
            <dwui-video-player id="educational-video" data-src="https://docwimgs01.blob.core.windows.net/video-pacientes/Docway-video-2.mp4"></dwui-video-player>
          </React.Fragment>
        ) : (step === STEPS.CHECKLIST ? (
          <React.Fragment>
            <s.Title type="h3_semibold">
              Uma consulta por telemedicina precisa do mesmo comprometimento de uma consulta presencial.
            </s.Title>
            <s.Image>
              <dwui-image-checklist small title="Meu titulo personalizado"></dwui-image-checklist>
            </s.Image>
            <s.Subtitle>
              Só para confirmar:
            </s.Subtitle>
            {topics.map((item) => (
              <s.Item key={item.id}>
                <Checkbox
                  id={item.id}
                  checked={item.checked}
                  onChange={() => handleSelected(item)}
                  value={item.id}
                />
                <label htmlFor={item.id}>
                  <Text type="p2_regular">{item.text}</Text>
                </label>
              </s.Item>
            ))}
          </React.Fragment>
        ) : (
          <SpinnerLoading color="green" />
        ))}
      </div>
      <s.Footer>
        <s.Message>
          O não cumprimento dessas diretrizes permitirá o profissional de 
          saúde <strong>encerrar</strong> o seu atendimento como erro.
        </s.Message>
        <Button
          color="green"
          disabled={disabled}
          onClick={handleNext}
        >
          <Text type="p2_regular" color={disabled ? 'gray3' : "green"}>
            Continuar
          </Text>
        </Button>
      </s.Footer>
    </s.Page>
  );
}

export default Checklist;