import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'
import ReactSelect from 'react-select'
import Text from 'components/Text'

export const Header = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Content = styled(Container)`
  padding-top: 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const Page = styled.div`
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
`

export const Item = styled.div`
  border-radius: 12px;
  box-shadow: 0 1px 4px 0 ${({ theme }) => theme.colors.gray40};
  height: 64px;
  width: 100%;
  padding-left: 16px;
  margin-top: 16px;
  font-weight: 500;
`

export const ContainerSpecialtyRadioButton = styled.div`
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  padding: 16px 0;
`

export const ContainerButtonNext = styled.div`
  justify-content: flex-end;
  display: flex;
  flex: 1;
  align-items: flex-end;
  margin: 40px 0;
`

export const ContainerButton = styled.div`
  flex: 1;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  width: 100%;

  button {
    width: 100%;
  }
`

export const SymptomsTextarea = styled.div`
  width: 100%;
  height: 189px;
`

export const InputDateAndHour = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  opacity: 0.6;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  padding: 0 16px 0 12px;

  input {
    border: none;
    width: 100%;
    ${({ theme }) => theme.fontTypes.p2_regular};
  }
`

export const Select = styled(ReactSelect)`
  .react-select__control {
    border-radius: 8px;
    border: 1px solid #707070;
    opacity: 0.6;
    box-shadow: none;
    :hover {
      border: 1px solid #707070;
    }

    &--is-disabled {
      background: lightgray;
    }
  }

  .react-select__indicator {
    padding: 0;
    padding-right: 16px;
    color: #707070;

    &-separator {
      display: none;
    }
  }
`

export const BrazilTimezoneMessage = styled(Text)`
  color: ${props => props.theme.colors.gray2};
  margin-top: 8px;
`
