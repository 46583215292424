import React from 'react'
import { ThemeProvider } from 'styled-components'
import theme from 'styles/theme'

import * as s from './styles'
import SweetAlert from '@sweetalert/with-react'

interface IProps {
  title: string
  message: string
  cancelButtonText?: string
  confirmButtonText?: string
  handleConfirm?: () => void
  handleCancel?: () => void
}

const ModalConfirm: React.FC<IProps> = ({
  title,
  message,
  cancelButtonText = 'Voltar',
  confirmButtonText = 'Sim',
  handleConfirm,
  handleCancel
}) => {
  return SweetAlert({
    title: title,
    text: message,
    buttons: false,
    content: (
      <ThemeProvider theme={theme}>
        <s.Buttons>
          <s.Cancel
            onClick={() => {
              if (handleCancel) handleCancel()

              SweetAlert.close()
            }}
          >
            {cancelButtonText}
          </s.Cancel>
          <s.Confirm
            onClick={() => {
              if (handleConfirm) handleConfirm()

              SweetAlert.close()
            }}
          >
            {confirmButtonText}
          </s.Confirm>
        </s.Buttons>
      </ThemeProvider>
    )
  })
}

export default ModalConfirm
