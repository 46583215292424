import React from 'react'
import { themeColors } from 'styles/theme'

import { ReactComponent as arrow } from 'assets/icons/ic-arrow.svg'
import { ReactComponent as arrowleft } from 'assets/icons/ic-backarrow.svg'
import { ReactComponent as arrowright } from 'assets/icons/arrow-right.svg'
import { ReactComponent as calendar } from 'assets/icons/ic-calendar.svg'
import { ReactComponent as camera } from 'assets/icons/ic-camera.svg'
import { ReactComponent as change } from 'assets/icons/ic-change.svg'
import { ReactComponent as check } from 'assets/icons/ic-check.svg'
import { ReactComponent as checkrounded } from 'assets/icons/check.svg'
import { ReactComponent as close } from 'assets/icons/close.svg'
import { ReactComponent as closerounded } from 'assets/icons/close-rounded.svg'
import { ReactComponent as doctor } from 'assets/icons/ic-doctor.svg'
import { ReactComponent as emergencia } from 'assets/icons/emergencia.svg'
import { ReactComponent as flash } from 'assets/icons/ic-flash.svg'
import { ReactComponent as historic } from 'assets/icons/ic-historic.svg'
import { ReactComponent as man } from 'assets/icons/ic-masculino.svg'
import { ReactComponent as microphone } from 'assets/icons/ic-microphone.svg'
import { ReactComponent as microphoneoff } from 'assets/icons/ic-microphoneoff.svg'
import { ReactComponent as naoidentificado } from 'assets/icons/naoidentificado.svg'
import { ReactComponent as people } from 'assets/icons/ic-people.svg'
import { ReactComponent as phone } from 'assets/icons/phone.svg'
import { ReactComponent as speakerphone } from 'assets/icons/ic-speakerphone.svg'
import { ReactComponent as spinner } from 'assets/icons/spinner.svg'
import { ReactComponent as symptomchecker } from 'assets/icons/symptom-checker.svg'
import { ReactComponent as symptomchecker2 } from 'assets/icons/symptom-checker2.svg'
import { ReactComponent as teleconsulta } from 'assets/icons/teleconsulta.svg'
import { ReactComponent as video } from 'assets/icons/ic-video.svg'
import { ReactComponent as videooff } from 'assets/icons/ic-videooff.svg'
import { ReactComponent as woman } from 'assets/icons/ic-feminino.svg'

export const dict = {
  arrow,
  arrowleft,
  arrowright,
  calendar,
  camera,
  change,
  check,
  checkrounded,
  close,
  closerounded,
  doctor,
  emergencia,
  flash,
  historic,
  man,
  microphone,
  microphoneoff,
  naoidentificado,
  people,
  phone,
  speakerphone,
  spinner,
  symptomchecker,
  symptomchecker2,
  teleconsulta,
  video,
  videooff,
  woman
}

interface IProps {
  name: keyof typeof dict
  color?: keyof typeof themeColors
}

const IconExporter = ({
  name,
  color,
  fill,
  ...rest
}: IProps & React.SVGAttributes<SVGElement>) => {
  const Icon = dict[name]
  return Icon ? (
    <Icon
      role="img"
      aria-label={name}
      fill={color ? themeColors[color] : fill}
      {...rest}
    />
  ) : null
}

export default IconExporter
