import styled from 'styled-components'
import { Container as ContainerBTS } from 'styled-bootstrap-grid'

import ButtonDefault from 'components/Button'
import Text from 'components/Text'
import Input from 'components/Input'

export const Page = styled(ContainerBTS)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  width: 100vw;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: flex-start;
  margin-bottom: 32px;
`

export const Message = styled(Text)`
  margin-top: 12px;
`

export const Email = styled(Input)`
  margin-top: 32px;
`

export const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 32px;
`

export const Button = styled(ButtonDefault)`
  width: 100%;
`
