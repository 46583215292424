import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import axios from 'services/api'
import { validateEmail } from 'helpers/validators'

import * as s from './styles'
import Text from 'components/Text'
import ModalMessage from 'components/ModalMessage'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'
import { useToast } from 'hooks/toast'

const ForgotPassword = () => {
  const history = useHistory()
  const { addToast } = useToast()

  const [isLoading, setLoading] = useState(false)

  const [email, setEmail] = useState({
    value: '',
    isValid: false,
    touched: false
  })

  function sendEmail() {
    setLoading(true)
    axios
      .post('/user/is-registered', {
        email: email.value
      })
      .then(data => {
        if (data.data) {
          axios
            .post('/user/sendforgotpasswordemail', {
              email: email.value
            })
            .then(() =>
              ModalMessage({
                title: 'E-mail enviado!',
                message:
                  'Agora é só entrar em seu e-mail e clicar no link para criar uma nova senha.',
                buttonText: 'OK!'
              })
            )
            .catch(() =>
              addToast({
                description: 'Um erro ocorreu. Tente novamente.',
                type: 'error',
                title: 'Falha na requisição.'
              })
            )
        } else {
          ModalMessage({
            title: 'Oops, acesso negado!',
            message:
              'As permissões de acesso ao aplicativo para este usuário foram alteradas. Por favor, crie um novo cadastro para que possamos verificar o seu perfil.',
            buttonText: 'Entendi!',
            hasSupportText: true,
            onClose: () => history.push('/cadastro')
          })
        }
      })
      .catch(() =>
        addToast({
          description: 'Um erro ocorreu. Tente novamente.',
          type: 'error',
          title: 'Falha na requisição.'
        })
      )
      .finally(() => setLoading(false))
  }

  return (
    <s.Page>
      <div>
        <s.Header>
          <BackIcon
            width={30}
            height={30}
            fill="gray"
            onClick={() => history.goBack()}
          />
        </s.Header>

        <Text type="h2_semibold">Insira o seu e-mail</Text>

        <s.Message type="p2_regular">
          Vamos te encaminhar um e-mail com mais informações sobre como
          redefinir sua senha
        </s.Message>

        <s.Email
          label="E-mail"
          value={email.value}
          onChange={({ target }) =>
            setEmail({
              ...email,
              value: target.value,
              isValid: validateEmail(target.value),
              touched: true
            })
          }
          hasError={!email.isValid && email.touched}
          errorMessage="Email inválido. Digite um email válido."
        />
      </div>

      <s.Footer>
        <s.Button
          color="green"
          disabled={!email.isValid}
          isLoading={isLoading}
          onClick={sendEmail}
        >
          Enviar e-mail
        </s.Button>
      </s.Footer>
    </s.Page>
  )
}

export default ForgotPassword
