import React, { useState } from 'react'
import Text from 'components/Text'
import { useHistory } from 'react-router-dom'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'
import { useToast } from 'hooks/toast'

import ModalMessage from 'components/ModalMessage'
import axios from 'services/api'

import * as s from './styles'

const Lgpd = () => {
  const history = useHistory()
  const [isLoading, setLoading] = useState(false)
  const { addToast } = useToast()

  const [description, setDescription] = useState({
    value: '',
    isValid: false
  })

  function handleBack() {
    history.push('/perfil/informacoes')
  }

  function sendRequest() {
    setLoading(true)
    axios
      .post('/lgpd/createrequest', {
        description: description.value
      })
      .then(() =>
        ModalMessage({
          title: 'Solicitação enviada!',
          message: 'Agora é só aguardar, entraremos em contato com você.',
          buttonText: 'OK!',
          onClose: () => handleBack()
        })
      )
      .catch(err => {
        let errorDescription = 'Um erro ocorreu. Tente novamente.'
        let errorTitle = 'Falha na requisição.'

        if (err.data && err.status === 400) {
          errorDescription = err.data[0].message
          errorTitle = 'Ops!'
        }

        addToast({
          type: 'error',
          title: errorTitle,
          description: errorDescription
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <s.Page>
      <div>
        <s.Header>
          <BackIcon
            width={30}
            height={30}
            fill="gray"
            onClick={() => handleBack()}
          />
        </s.Header>

        <Text type="h2_semibold">Proteção de Dados (LGPD)</Text>

        <s.Message type="font10">
          Solicite ações relacionadas aos seus dados cadastrados na Docway. Você
          pode: solicitar o registro de dados, pedir informações sobre a
          utilização deles ou solicitar a exclusão.
        </s.Message>

        <s.Field
          onChange={({ target }) =>
            setDescription({
              ...description,
              value: target.value,
              isValid: target.value.length > 0
            })
          }
          disabled={isLoading}
        />
      </div>

      <s.Footer>
        <s.Button
          color="green"
          disabled={!description.isValid || isLoading}
          isLoading={isLoading}
          onClick={sendRequest}
        >
          Enviar
        </s.Button>
      </s.Footer>
    </s.Page>
  )
}

export default Lgpd
