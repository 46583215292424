import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'
import { themeColors } from 'styles/theme'
import { ReactComponent as PeopleIcon } from 'assets/icons/ic-people-white.svg'

export interface IStyledProps {
  color: keyof typeof themeColors
}

export interface IImageProps {
  width: string
  height: string
  radious: string
  backgroundColor?: keyof typeof themeColors
}

export const Page = styled.div`
  width: 100vw;
  height: 100vh;
`
export const Header = styled(Container)<IStyledProps>`
  position: relative;
  top: 20px;
  height: 224px;
  text-align: center;
`

export const ImageContainer = styled.div<IImageProps>`
  width: ${props => props.width};
  height: ${props => props.width};
  text-align: center;
  margin: 25px auto;

  img {
    width: 100%;
    height: 100%;
    border-radius: ${props => props.width};
  }
  div {
    width: 100%;
    height: 100%;
    border-radius: ${props => props.width};
    background-color: ${({ theme, backgroundColor }) =>
      theme.colors[backgroundColor]};
    align-items: center;
    justify-content: center;
    display: flex;
  }
`
export const People = styled(PeopleIcon)`
  height: 50px;
  width: 50px;
`

export const Body = styled(Container)<IStyledProps>`
  bottom: 10px;

  div {
    border-bottom: 1px solid ${({ theme, color }) => theme.colors[color]};
    height: 60px;
  }

  p {
    padding: 20px 0px 20px 10px;
    float: left;
  }
`
export const Logout = styled.div<IStyledProps>`
  border-bottom: none !important;
  margin-top: 60px;
  p {
    color: ${props => props.theme.colors[props.color]};
  }
`
