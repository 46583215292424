import styled from 'styled-components'

import { Container } from 'styled-bootstrap-grid'
import Text from 'components/Text'

export const Page = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: flex-start;
  margin-bottom: 32px;
`

export const Icon = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
`

export const Title = styled(Text)`
  margin-top: 32px;
  text-align: center;
`

export const Message = styled(Text)`
  margin-top: 12px;
  text-align: center;
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`
