import React, { useContext } from 'react'

import * as s from './styles'
import ButtonNext from 'components/ButtonNext'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'
import MicrophonePermissionIcon from 'assets/icons/ic-microphone-permission.svg'

import { Context } from 'hooks/BeforeAttendanceContext'

const InformCheckMicrophone = () => {
  const { setCurrentStep } = useContext(Context)

  function handleNext() {
    setCurrentStep('CHECK_MICROPHONE')
  }

  return (
    <s.Page>
      <div>
        <s.Header>
          <BackIcon
            width={30}
            height={30}
            fill="gray"
            onClick={() => setCurrentStep('CHECK_CAMERA')}
          />
        </s.Header>

        <s.Icon src={MicrophonePermissionIcon} />

        <s.Title type="h3_semibold">O médico vai precisar te ouvir!</s.Title>
        <s.Message type="p2_regular">
          Vamos precisar que você permita o acesso ao seu microfone para que
          possa explicar ao médico o que está sentindo e esclarecer todas as
          suas dúvidas!
        </s.Message>
      </div>

      <s.Footer>
        <ButtonNext onClick={handleNext} />
      </s.Footer>
    </s.Page>
  )
}

export default InformCheckMicrophone
