import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Room from 'components/Room'
import IconExporter from 'components/IconExporter'
import * as s from './styles'
import ModalConfirm from 'components/ModalConfirm'
import logo from 'assets/images/d.png'

import { ReactComponent as AlertReschedule } from 'assets/icons/Group-1.svg'
import { useMutate, useGet } from 'restful-react'
import axiosInstance from 'services/api'

type RoomData = {
  name: string
  token: string
}

const Attendance = () => {
  const [disconnected, setDisconnected] = useState(false)
  const [roomData, setRoomData] = useState<RoomData | null>(null)
  const [openReschedule, setOpenReschedule] = useState(false)

  const history = useHistory()
  const { appointmentId } = useParams() as any

  const handleUserDisconnection = useCallback(() => {
    setDisconnected(true)
    setRoomData(null)
  }, [])

  const {
    loading: loadingRegister,
    mutate: registerUnsuccessfulAppointment
  } = useMutate('POST', `appointment/${appointmentId}/unsuccessful`)

  const { loading: loadingToken, data } = useGet(
    `appointment/${appointmentId}/token`
  )

  const { loading: loadingAppointmentData, data: appointmentData } = useGet(
    `appointment/${appointmentId}/data`
  )

  const { data: rescheduleData, refetch: rescheduleRefetch } = useGet(
    `appointment/changedhistorybyid/${appointmentId}`
  )

  const recheduleCountRef = useRef(rescheduleData)
  recheduleCountRef.current = rescheduleData

  const loading = loadingToken || loadingRegister || loadingAppointmentData

  useEffect(() => {
    const checkForReschedule = () => {
      if (recheduleCountRef.current) {
        setOpenReschedule(true)
      } else {
        rescheduleRefetch()
        setTimeout(() => checkForReschedule(), 5000)
      }
    }

    if (rescheduleData) {
      setOpenReschedule(true)
    } else {
      checkForReschedule()
    }
  }, [])

  useEffect(() => {
    if (data) {
      setRoomData(data)
    }
  }, [data])

  useEffect(() => {
    if (!appointmentId) {
      history.push('/historico')
    }
  }, [appointmentId, history])

  useEffect(() => {
    if (disconnected) {
      if (
        !appointmentData.isSpecialist &&
        !appointmentData.isReturn &&
        !appointmentData.fromForwarding
      ) {
        openModalConfirmation()
        return
      }
      handleConfirmation()
    }
  }, [appointmentId, disconnected, history])

  function openModalConfirmation() {
    if (openReschedule) {
      return null
    }
    return ModalConfirm({
      title: 'Seu atendimento foi realizado com sucesso?',
      message: '',
      cancelButtonText: 'Não',
      handleConfirm: handleConfirmation,
      handleCancel
    })
  }

  function handleConfirmation() {
    axiosInstance
      .get(`appointment/professionalType/${appointmentId}`)
      .then(response => {
        localStorage.setItem('isDoctor', response.data === 1 ? 'true' : 'false')
      })

    axiosInstance.get(`appointment/isMatch/${appointmentId}`).then(response => {
      localStorage.setItem('isAppointmentMatch', response.data.toString())

      history.push(
        `/dashboard?appointmentId=${appointmentId}&feedbackModalOpen=${true}`
      )
    })
  }

  const handleCancel = async () => {
    try {
      await registerUnsuccessfulAppointment({})
    } finally {
      history.push(
        `/dashboard?appointmentId=${appointmentId}&unsuccessfulModalOpen=${true}`
      )
    }
  }

  return (
    <s.Container>
      <s.Header>
        <IconExporter
          name="arrowleft"
          width={30}
          height={30}
          color="gray"
          onClick={() => {
            history.push('/dashboard')
          }}
        />
      </s.Header>
      {openReschedule ? (
        <s.RescheduleContainer>
          <AlertReschedule />
          <s.Title>
            Desculpe, o seu atendimento precisou ser reagendado{' '}
          </s.Title>
          <s.SubTitle>
            Veja os detalhes sobre o novo agendamento que enviamos por email,
            SMS e/ou Whatsapp
          </s.SubTitle>
        </s.RescheduleContainer>
      ) : roomData && !loading ? (
        <Room
          token={roomData.token}
          name={roomData.name}
          onLocalUserDisconnected={handleUserDisconnection}
          appointmentId={appointmentId}
        />
      ) : (
        <s.LoadingContainer>
          <s.Animation>
            <s.LogoD src={logo} />
          </s.Animation>
          <s.Title>Tentando conectar novamente</s.Title>
          <br />
          <s.SubTitle>
            Aguarde uns instantes enquanto estamos conectando você novamente ao
            profissional da saúde
          </s.SubTitle>
        </s.LoadingContainer>
      )}
    </s.Container>
  )
}

export default Attendance
