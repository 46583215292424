import styled from 'styled-components'

import Icon from 'components/IconExporter'
import Text from 'components/Text'
import { themeColors } from 'styles/theme'
import { ReactComponent as PeopleIcon } from 'assets/icons/ic-people-white.svg'
import { ReactComponent as ArrowIcon } from 'assets/icons/ic-arrow.svg'

interface IStatusProps {
  status: number
}

interface IStyledProps {
  backgroundColor?: keyof typeof themeColors
  color?: keyof typeof themeColors
}

export const Container = styled.div`
  align-items: center;
  display: flex;
  padding: 16px 8px;
`

export const DoctorPhoto = styled.img`
  border-radius: 50px;
  height: 40px;
  width: 40px;
`

export const PeopleContainer = styled.div<IStyledProps>`
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background-color: ${props => props.theme.colors[props.backgroundColor]};
  display: inline-flex;
  justify-content: center;
`

export const People = styled(PeopleIcon)`
  width: 50%;
  height: 50%;
  align-self: center;
`

export const Arrow = styled(ArrowIcon)`
  width: 16px;
  height: 16px;
  transform: rotate(270deg);
  fill: ${props => props.theme.colors['gray']};
`

export const InfoContainer = styled.div`
  padding-left: 16px;
  width: 80%;
  max-width: 80%;
`

export const DoctorName = styled(Text)<IStyledProps>`
  p {
    color: ${props => props.theme.colors[props.color]};
  }
`

export const DateAppointment = styled(Text)<IStyledProps>`
  margin-top: 8px;
  p {
    color: ${props => props.theme.colors[props.color]};
  }
`

function handleStatusColor(status: number): keyof typeof themeColors {
  switch (status) {
    case 8:
    case 10:
    case 11:
      return 'red'
    case 12:
      return 'yellow'
    default:
      return 'green5'
  }
}

function handleStatusBackgroundColor(status: number): keyof typeof themeColors {
  switch (status) {
    case 8:
    case 10:
    case 11:
      return 'red4'
    case 12:
      return 'yellow4'
    default:
      return 'green4'
  }
}

export const StatusContainer = styled.div<IStatusProps>`
  align-items: center;
  background-color: ${props =>
    props.theme.colors[handleStatusBackgroundColor(props.status)]};
  border-radius: 8px;
  display: flex;
  margin-top: 8px;
  padding: 4px 12px;
  width: fit-content;
`

export const VideoIcon = styled(Icon).attrs(() => ({}))<IStatusProps>`
  height: 16px;
  width: 16px;
  fill: ${props => props.theme.colors[handleStatusColor(props.status)]};
`

export const Status = styled(Text)<IStatusProps>`
  color: ${props => props.theme.colors[handleStatusColor(props.status)]};
  margin-left: 4px;
`

export const Divider = styled.hr`
  border: 1px solid ${props => props.theme.colors.gray3};
  background-color: ${props => props.theme.colors.gray3};
`
