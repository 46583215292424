import React, { useState, useEffect, useRef } from 'react'

import {
  AudioTrack,
  VideoTrack,
  Participant as IParticipant,
  Track
} from 'twilio-video'
import * as s from './styles'
import Text from 'components/Text'
import { Doctor } from 'types/Appointment'

const Participant = ({
  participant,
  doctor,
  isMiniVideo,
  ...rest
}: {
  participant: IParticipant
  doctor: Doctor
  isMiniVideo?: boolean
}) => {
  const [videoTracks, setVideoTracks] = useState([])
  const [audioTracks, setAudioTracks] = useState([])
  const [isVideoOn, setIsVideOn] = useState<boolean>(true)

  const videoRef = useRef()
  const audioRef = useRef()

  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter(track => track !== null)

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks))
    setAudioTracks(trackpubsToTracks(participant.audioTracks))

    if (!isMiniVideo) {
      participant.on('trackEnabled', (track: Track) => {
        console.log(track)
        if (track.kind === 'video') {
          setIsVideOn(true)
        }
      })

      participant.on('trackDisabled', (track: Track) => {
        if (track.kind === 'video') {
          setIsVideOn(false)
        }
      })
    }

    const trackSubscribed = (track: AudioTrack | VideoTrack) => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => [...videoTracks, track])
      } else if (track.kind === 'audio') {
        setAudioTracks(audioTracks => [...audioTracks, track])
      }
    }

    const trackUnsubscribed = (track: AudioTrack | VideoTrack) => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track))
      } else if (track.kind === 'audio') {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track))
      }
    }

    participant.on('trackSubscribed', trackSubscribed)
    participant.on('trackUnsubscribed', trackUnsubscribed)

    // eslint-disable-next-line
    // @ts-ignore
    participant.tracks.forEach((track: Track) => {
      // eslint-disable-next-line
      // @ts-ignore
      if (track.kind === 'video' && !track.isTrackEnabled) {
        setIsVideOn(false)
      }

      // eslint-disable-next-line
      // @ts-ignore
      if (track.kind === 'video' && track.isTrackEnabled) {
        setIsVideOn(true)
      }
    })

    return () => {
      setVideoTracks([])
      setAudioTracks([])
      participant.removeAllListeners()
    }
  }, [participant])

  useEffect(() => {
    const videoTrack = videoTracks[0]
    if (videoTrack) {
      videoTrack.attach(videoRef.current)
      return () => {
        videoTrack.detach()
      }
    }
  }, [videoTracks])

  useEffect(() => {
    const audioTrack = audioTracks[0]
    if (audioTrack) {
      audioTrack.attach(audioRef.current)
      return () => {
        audioTrack.detach()
      }
    }
  }, [audioTracks])

  return (
    <div {...rest}>
      {
        !isVideoOn && (
          <s.NoVideo>
            <s.DoctorPhoto src={doctor ? doctor.profilePicture : ''} />
            <Text color="white" textAlign="center">
              {doctor ? `${doctor.firstName} ${doctor.lastName}` : ''}
            </Text>
            <Text color="white" textAlign="center" style={{ marginTop: 20 }}>
              O profissional de saúde está na sala, mas com a câmera desligada. Aguarde :)
            </Text>
          </s.NoVideo>
        )
      }
      <video ref={videoRef} autoPlay={true} style={{
        display: isVideoOn ? null : 'none'
      }} />
      <audio ref={audioRef} autoPlay={true} />
    </div>
  )
}

export default Participant
