import styled from 'styled-components'

import { Row, Col } from 'styled-bootstrap-grid'
import Icon from 'components/IconExporter'
import Text from 'components/Text'
import { themeColors } from 'styles/theme'

interface IStatusProps {
  status: number
}

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  width: 100vw;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  min-height: 56px;
  justify-content: flex-start;
`

export const DoctorContainer = styled(Row)`
  margin-top: 32px;
`

export const DoctorStartContainer = styled(Col)``

export const DoctorName = styled(Text)``

export const DateAppointment = styled(Text)`
  margin-top: 8px;
`

export const PatientName = styled(Text)`
  color: ${props => props.theme.colors.gray2};
  margin-top: 8px;
`

export const DoctorEndContainer = styled(Col)`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`

export const DoctorImage = styled.img`
  border-radius: 50px;
  height: 40px;
  width: 40px;
`

function handleStatusBackgroundColor(status: number): keyof typeof themeColors {
  switch (status) {
    case 8:
    case 10:
    case 11:
      return 'red'

    case 12:
      return 'yellow'
    default:
      return 'green'
  }
}

export const StatusContainer = styled.div<IStatusProps>`
  align-items: center;
  background-color: ${props =>
    props.theme.colors[handleStatusBackgroundColor(props.status)]};
  border-radius: 8px;
  display: flex;
  margin-top: 4px;
  padding: 2px 8px;
  width: fit-content;
`

export const VideoIcon = styled(Icon).attrs(() => ({
  color: 'white'
}))`
  height: 16px;
  width: 16px;
`

export const Status = styled(Text)`
  color: ${props => props.theme.colors.white};
  margin-left: 4px;
`

export const BrazilTimezoneMessage = styled(Text)`
  color: ${props => props.theme.colors.gray2};
  margin-top: 16px;
`

export const Divider = styled.hr`
  border: 0 none;
  border-top: 2px dashed ${props => props.theme.colors.gray2};
  margin-top: 32px;
`

export const Symptom = styled(Text)``

export const Specialty = styled(Text)`
  margin-top: 24px;
`

export const PartnerName = styled(Text)`
  margin-top: 8px;
`
