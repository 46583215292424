import React, { createContext, FunctionComponent, useCallback } from 'react'
import { RestfulProvider as RestfulReactProvider } from 'restful-react'
import { useToast } from './toast'
import localStorage, { getUserAuth } from 'helpers/localStorage'
import history from 'services/history'

const RestfulContext = createContext({})

const RestfulProvider: FunctionComponent = ({ children }) => {
  const { addToast } = useToast()

  const onError = useCallback(error => {
    if (error.status === 401) {
      localStorage.removeUserAuth()
      history.push('/')
      addToast({
        type: 'error',
        title: 'Falha na requisição.',
        description: 'Sessão expirada. Faça login novamente.'
      })
    } else {
      if (error.data && error.status === 400) {
        addToast({
          type: 'error',
          title: 'Ops!',
          description:
            error.data[0].message || 'Um erro ocorreu. Tente novamente.'
        })
      } else {
        addToast({
          type: 'error',
          title: 'Falha na requisição.',
          description: 'Sem conexão com a internet.'
        })
      }
    }
  }, [])

  const requestOptions = useCallback(
    () => ({
      headers: {
        Authorization: `Bearer ${getUserAuth().accessToken}`
      }
    }),
    []
  )

  return (
    <RestfulContext.Provider value={{}}>
      <RestfulReactProvider
        base={process.env.REACT_APP_API}
        onError={onError}
        requestOptions={requestOptions}
      >
        {children}
      </RestfulReactProvider>
    </RestfulContext.Provider>
  )
}

export { RestfulContext, RestfulProvider }
