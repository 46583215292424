import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: 'Maven Pro', sans-serif;
  }

  body {
    box-sizing: border-box;
  }

  input[type='radio'] {
    outline: none;
    /* remove standard background appearance */
    appearance: none;
    width: 15px;
    height: 15px;
    padding: 2px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #bbbbbb;
    background-color: transparent;
    border-radius: 50%;
    display: table;
  }

  /* appearance for checked radiobutton */
  input[type='radio']:checked {
    background-color: ${({ theme }) => theme.colors.green};
    border: 2px solid ${({ theme }) => theme.colors.green};
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
  }

  .swal-title {
    font-size: 20px;
    line-height: 1.2;
  }

  .swal-text {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }

  .swal-content {
    padding-left: 0;
    padding-right: 0;
  }

  .swal-content:last-child {
    margin-bottom: 0;
  }
`
