import styled from 'styled-components'
import IconExporter from 'components/IconExporter'

export const Container = styled.div`
  height: 48px;
  width: 100%;
  box-shadow: 0 1px 6px 0 ${({ theme }) => theme.colors.gray40};
  position: fixed;
  bottom: 0;
  background-color: white;

  display: flex;
  justify-content: space-around;
  align-items: center;
`

interface IPropsIcon {
  selected: boolean
}

export const Icon = styled(IconExporter).attrs((props: IPropsIcon) => ({
  color: props.selected ? 'green' : 'gray'
}))<IPropsIcon>``
