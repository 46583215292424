import React from 'react'
import { Switch, Route } from 'react-router-dom'

import FooterMenu from 'components/FooterMenu'

import Welcome from 'pages/Welcome'
import Register from 'pages/Register'
import Login from 'pages/Login'
import ForgotPassword from 'pages/ForgotPassword'
import Profile from 'pages/Profile'
import LegalInformations from 'pages/LegalInformations'
import Lgpd from 'pages/Lgpd'
import ChangePassword from 'pages/ChangePassword'
import EditProfile from 'pages/EditProfile'
import NewPassword from 'pages/NewPassword'
import Dashboard from 'pages/Dashboard'
import Historic from 'pages/Historic'
import HistoricDetails from 'pages/HistoricDetails'
import CreateAttendance from 'pages/CreateAttendance'
import BeforeAttendance from 'pages/BeforeAttendance'
import Attendance from 'pages/Attendance'
import AppointmentSorryInfo from 'pages/AppointmentSorryInfo'
import Checklist from 'pages/BeforeAttendance/Steps/Checklist'
import AgeSorryInfo from 'pages/AgeSorryInfo'

const RouteWithMenu = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <>
        <Component {...props} />
        <FooterMenu />
      </>
    )}
  />
)

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Welcome} />
    <Route path="/cadastro" component={Register} />
    <Route exact path="/login/:appointmentId?" component={Login} />
    <Route exact path="/nova-senha" component={NewPassword} />
    <Route path="/esqueceu-sua-senha" component={ForgotPassword} />
    <Route path="/perfil/informacoes" component={LegalInformations} />
    <Route path="/perfil/meus-dados" component={Lgpd} />
    <Route path="/perfil/trocar-senha" component={ChangePassword} />
    <Route path="/perfil/editar" component={EditProfile} />
    <Route path="/perfil/meusdados" component={Lgpd} />
    <Route path="/historico/:id" component={HistoricDetails} />
    <Route path="/criar-atendimento" component={CreateAttendance} />
    <Route path="/antessala/:appointmentId" component={BeforeAttendance} />
    <Route path="/attendance/:appointmentId" component={Attendance} />
    <Route path="/atendimento-info" component={AppointmentSorryInfo} />
    <Route path="/checklist" component={Checklist} />
    <Route path="/validacao" component={() => <EditProfile isValidation />} />
    <Route path="/validacao-idade" component={() => <AgeSorryInfo />} />

    <RouteWithMenu exact path="/perfil" component={Profile} />
    <RouteWithMenu path="/dashboard" component={Dashboard} />
    <RouteWithMenu exact path="/historico" component={Historic} />
  </Switch>
)

export default Routes
