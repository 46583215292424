import React, { useEffect } from 'react'

import Text from 'components/Text'
import { IToast, useToast } from 'hooks/toast'
import * as s from './styles'

interface ToastProps {
  toast: IToast
  style: unknown
}

const icons = {
  error: <s.IconStyled name="closerounded" color="red" />,
  success: <s.IconStyled name="checkrounded" color="green3" />
}

const Toast: React.FC<ToastProps> = ({ toast, ...rest }) => {
  const { removeToast } = useToast()

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(toast.id)
    }, 5000)
    return () => {
      clearTimeout(timer)
    }
  }, [removeToast, toast.id])

  return (
    <s.Container key={toast.id} type={toast.type} {...rest}>
      <div>{icons[toast.type] || icons.success}</div>
      <s.InfoContainer>
        <Text type="font13">{toast.title || 'NO TITLE WAS PROVIDED'}</Text>
        <s.DescriptionText type="font6">
          {toast.description || 'NO DESCRIPTION WAS PROVIDED'}
        </s.DescriptionText>
      </s.InfoContainer>

      <s.CloseToastIconButton
        name="close"
        color="gray2"
        onClick={() => removeToast(toast.id)}
      />
    </s.Container>
  )
}

export default Toast
