import styled, { css } from 'styled-components'
import { Container as ContainerBTS } from 'styled-bootstrap-grid'
import { Dialog } from '@material-ui/core'

import ButtonDefault from 'components/Button'
import IconExporter from 'components/IconExporter'
import Text from 'components/Text'

export const Container = styled.div`
  height: 100vh;
  display: grid;
`

export const Content = styled(ContainerBTS)`
  display: flex;
  height: calc(100% - 70px);
  align-items: flex-end;
`

export const Button = styled(ButtonDefault)`
  width: 100%;
`

export const Modal = styled(Dialog)`
  .MuiDialog-paper {
    margin: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`

export const Title = styled(Text)`
  margin-bottom: 4px;
`

export const SchedulingAttendanceContainer = styled.div`
  position: relative;
  align-self: flex-end;
`

export const SchedulingAttendance = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    height: 360px;
    background-color: ${theme.colors.green};
    clip-path: ellipse(105% 100% at 30% 100%);
  `}
`

export const LighterGreenBorder = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.green3};
    position: absolute;
    width: 100%;
    height: 300px;
    top: 0px;
    z-index: -1;
    clip-path: ellipse(100% 93% at 50% 100%);
  `}
`
