import styled from 'styled-components'

import IconExporter from 'components/IconExporter'

export const WaveformContainer = styled.div`
  align-items: center;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`

export const Wave = styled.div``

export const Icon = styled(IconExporter)`
  fill: ${props => props.theme.colors.white};
  height: 140px;
  margin-top: 32px;
  width: 140px;
`
