import styled, { keyframes } from 'styled-components'
import { ReactComponent as Icon } from 'assets/icons/spinner.svg'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

export const IconAnimated = styled(Icon)`
  animation: ${rotate} 1s linear infinite;
`
