import React from 'react'
import { themeColors, fontTypes } from 'styles/theme'

import * as s from './styles'

export interface IProps {
  color?: keyof typeof themeColors
  textAlign?: 'left' | 'center' | 'right'
  type?: keyof typeof fontTypes
}

const Text: React.FC<IProps & React.HTMLAttributes<HTMLParagraphElement>> = ({
  type = 'font1',
  color = 'gray',
  textAlign = 'left',
  children,
  ...rest
}) => (
  <s.TextStyle color={color} type={type} textAlign={textAlign} {...rest}>
    {children}
  </s.TextStyle>
)

export default Text
