import { useCallback, useEffect, useState } from 'react'

export const useConnectivity = (): Connectivity => {
  const [isOnline, setIsOnline] = useState<boolean>(true)

  const online = useCallback(() => {
    setIsOnline(true)
  }, [setIsOnline])

  const offline = useCallback(() => {
    setIsOnline(false)
  }, [setIsOnline])

  const checkStatus = useCallback(() => {
    const xhr = new XMLHttpRequest()

    xhr.onerror = offline
    xhr.ontimeout = offline

    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED) {
        if (xhr.status) {
          online()
        } else {
          offline()
        }
      }
    }

    xhr.open('HEAD', 'https://ipv4.icanhazip.com/')
    xhr.timeout = 5000
    xhr.send()
    if (navigator.onLine) {
      online()
    } else {
      offline()
    }
  }, [online, offline])

  useEffect(() => {
    // const interval = setInterval(() => {
    //   checkStatus()
    // }, 5000)
    // return () => {
    //   clearInterval(interval)
    // }
  }, [checkStatus])

  return {
    isOnline,
    checkStatus
  }
}

interface Connectivity {
  isOnline: boolean
  checkStatus(): void
}
