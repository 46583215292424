import { useState, useCallback } from 'react'
import TwilioVideo, {
  LocalAudioTrack,
  LocalVideoTrack,
  CreateLocalTrackOptions
} from 'twilio-video'
import { useHasAudioInputDevices, useHasVideoInputDevices } from './deviceHooks'

export default function useLocalTracks() {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>()
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>()
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false)

  const hasAudio = useHasAudioInputDevices()
  const hasVideo = useHasVideoInputDevices()

  const getLocalAudioTrack = useCallback((deviceId?: string) => {
    const options: CreateLocalTrackOptions = {}

    if (deviceId) {
      options.deviceId = { exact: deviceId }
    }

    return TwilioVideo.createLocalAudioTrack(options).then(newTrack => {
      setAudioTrack(newTrack)
      return newTrack
    })
  }, [])

  const getLocalVideoTrack = useCallback(
    (newOptions?: CreateLocalTrackOptions) => {
      const options: CreateLocalTrackOptions = {
        width: 1280,
        height: 720,
        frameRate: 24,
        name: `camera-${Date.now()}`,
        facingMode: 'user',
        ...newOptions
      }

      return TwilioVideo.createLocalVideoTrack(options).then(newTrack => {
        setVideoTrack(newTrack)
        return newTrack
      })
    },
    []
  )

  const createAudioAndVideoTracks = useCallback(() => {
    if (!hasAudio && !hasVideo) return Promise.resolve()

    setIsAcquiringLocalTracks(true)
    return TwilioVideo.createLocalTracks({
      video: !videoTrack &&
        hasVideo && {
          width: 1280,
          height: 720,
          frameRate: 24,
          name: `camera-${Date.now()}`
        },
      audio: !audioTrack && hasAudio
    })
      .then(tracks => {
        const videoTrack = tracks.find(track => track.kind === 'video')
        const audioTrack = tracks.find(track => track.kind === 'audio')
        if (videoTrack) {
          setVideoTrack(videoTrack as LocalVideoTrack)
        }
        if (audioTrack) {
          setAudioTrack(audioTrack as LocalAudioTrack)
        }
      })
      .finally(() => setIsAcquiringLocalTracks(false))
  }, [hasAudio, hasVideo, audioTrack, videoTrack])

  const localTracks = [audioTrack, videoTrack].filter(
    track => track !== undefined
  ) as (LocalAudioTrack | LocalVideoTrack)[]

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    createAudioAndVideoTracks
  }
}
