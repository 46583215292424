import styled from 'styled-components'

import { InputStyledMask } from 'components/Input/styles'

interface IPropsHasError {
  hasError: boolean
}

export const Container = styled.div<IPropsHasError>`
  position: relative;
  label {
    color: ${({ hasError }) => {
      if (hasError) {
        return 'red'
      }
      return 'initial'
    }};
    opacity: 0.6;
    pointer-events: none;
    transition: 0.25s ease-in-out;
    margin-left: 5px;
    position: absolute;
    bottom: 26px;
    ${({ theme }) => theme.fontTypes.font6};
  }
`

export const Input = styled.div`
  display: flex;

  ${InputStyledMask} {
    padding-left: 4px;
  }
`

export const Ddi = styled.select<IPropsHasError>`
  border: none;
  border-bottom: 1px solid
    ${({ hasError, theme }) => (hasError ? theme.colors.red : '#ccc')};
  width: 140px;
  outline: none;
  background-color: transparent;

  ${({ theme }) => theme.fontTypes.font5};
`
