import styled, { css, keyframes } from 'styled-components'

export const Container = styled.div`
  position: relative;
  flex: 1;
`

export const Header = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`

export const Controls = styled.div`
  position: absolute;
  bottom: 40px;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

const commonStylesButton = css`
  border-radius: 50%;
  border: none;
  outline: none;
`

export const SmallerButton = styled.button`
  ${commonStylesButton};
  height: 44px;
  width: 44px;
  background-color: ${({ theme }) => theme.colors.white};
`

export const HangoutButton = styled.button`
  ${commonStylesButton};
  height: 60px;
  width: 60px;
  background-color: ${({ theme }) => theme.colors.red};
`

export const LoadingContainer = styled.div`
  display: flex;
  height: calc(100vh - 80px);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #000;
  padding: 0 60px;
`
export const Title = styled.p`
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`

export const SubTitle = styled.p`
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
`

const LogoAnimation = keyframes`
  0% { opacity: 0 }
  50% { opacity: 1 }
  100% { opacity: 0 }
`

export const Animation = styled.div`
  animation: ${LogoAnimation} 2s infinite;
  margin-bottom: 32px;
`
export const LogoD = styled.img`
  width: 54px;
  height: 56px;
`

export const RescheduleContainer = styled.div`
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #000;
  padding: 0 16px;

  svg {
    margin: 15px 0;
  }

  p {
    margin: 5px 0;
  }
`
