import React, { useState, useEffect, useContext } from 'react'
import Text from 'components/Text'
import { useHistory } from 'react-router-dom'

import { removeUserAuth } from 'helpers/localStorage'

import SpinnerLoading from 'components/SpinnerLoading'
import * as s from './styles'
import OfflineBar from 'components/OfflineBar'
import { ConnectivityContext } from 'hooks/ConnectivityContext'
import { useGet } from 'restful-react'

const Profile = () => {
  const history = useHistory()
  const [profilePicture, setProfilePicture] = useState('')
  const [firstName, setfirstName] = useState('')

  const { isOnline } = useContext(ConnectivityContext)

  const { loading, data } = useGet('beneficiary/getdata')

  useEffect(() => {
    if (data) {
      setProfilePicture(data.profilePicture.pictureUrl)
      setfirstName(data.name.firstName)
    }
  }, [data])

  function handleLegalInformations() {
    history.push('/perfil/informacoes')
  }

  function handleChangePassword() {
    history.push('/perfil/trocar-senha')
  }

  function handleEditProfile() {
    history.push('/perfil/editar')
  }

  function handleLogout() {
    localStorage.removeItem('surveyPatientHasBeenFilled')
    removeUserAuth()
    history.push('/')
  }

  return loading ? (
    <SpinnerLoading></SpinnerLoading>
  ) : (
    <>
      <OfflineBar />
      <s.Page>
        <s.Header color="green">
          <Text type={'font1'} textAlign={'center'}>
            Olá {firstName},
          </Text>
          <s.ImageContainer
            width="100px"
            height="100px"
            radious="50px"
            backgroundColor="green"
          >
            {profilePicture ? (
              <img src={profilePicture} alt="profile"></img>
            ) : (
              <div>
                <s.People />
              </div>
            )}
          </s.ImageContainer>
        </s.Header>
        <s.Body color="grayLight">
          <div onClick={() => (isOnline ? handleEditProfile() : null)}>
            <Text type={'font5'}>Minha conta</Text>
          </div>

          <div onClick={() => handleChangePassword()}>
            <Text type={'font5'}>Trocar senha</Text>
          </div>

          <div onClick={() => handleLegalInformations()}>
            <Text type={'font5'}>Informações legais</Text>
          </div>

          <s.Logout color="gray2">
            <Text type={'font5'} onClick={handleLogout}>
              Desconectar
            </Text>
          </s.Logout>
        </s.Body>
      </s.Page>
    </>
  )
}

export default Profile
