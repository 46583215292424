export function validateEmail(email: string): boolean {
  const regex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,4})+$/
  return regex.test(email)
}

export function validatePassword(password) {
  const regex = new RegExp(
    '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()_+\\-={}|\\\\:"";\'<>?,. \\/\\s]).{10,}$'
  )
  return regex.test(password)
}

export function validateCEP(cep) {
  const regex = new RegExp('^[0-9]{5}-[0-9]{3}$')
  return regex.test(cep)
}

export function validatePhoneNumber(phoneNumber) {
  const regex = new RegExp('^[0-9]{5}-[0-9]{4}$')
  return regex.test(phoneNumber)
}
