import React from 'react'
import { themeColors } from 'styles/theme'

import SpinnerLoading from 'components/SpinnerLoading'

import * as s from './styles'

interface IProps {
  color?: keyof typeof themeColors
  isLoading?: boolean
  variant?: 'contained' | 'raised'
}

const Button: React.FC<
  IProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  color = 'gray',
  isLoading = false,
  variant = 'raised',
  children,
  ...rest
}) => {
  return (
    <s.Container color={color} variant={variant} {...rest}>
      {isLoading ? <SpinnerLoading color={color} /> : children}
    </s.Container>
  )
}

export default Button
