import styled from 'styled-components'
import { themeColors } from 'styles/theme'

import { ReactComponent as Arrow } from 'assets/icons/ic-arrow.svg'

interface IStyleProps {
  color: keyof typeof themeColors
}

interface IArrowRightProps {
  color: keyof typeof themeColors
  disabled: boolean | undefined
}

function handleColor(
  disabled: boolean | undefined,
  color: keyof typeof themeColors,
  colors: { [key in keyof typeof themeColors]: string }
) {
  if (disabled) {
    return colors.gray3
  } else {
    return colors[color]
  }
}

export const ArrowRight = styled(Arrow)<IArrowRightProps>`
  transform: rotate(270deg);
`

export const Button = styled.button<IStyleProps>`
  height: 40px;
  width: 40px;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  border: 2px solid
    ${props => handleColor(props.disabled, props.color, props.theme.colors)};
  background-color: ${props => props.theme.colors['white']};

  ${ArrowRight} {
    fill: ${props =>
      handleColor(props.disabled, props.color, props.theme.colors)};
  }
`
