import React, { useState, useRef, useContext } from 'react'

import * as s from './styles'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'
import ModalMessage from 'components/ModalMessage'

import { Context } from 'hooks/BeforeAttendanceContext'

import Waveform from './Waveform'

const CheckMicrophone = () => {
  const { setCurrentStep } = useContext(Context)
  const [microphoneStatus, setMicrophoneStatus] = useState(null)

  const audioRef = useRef()

  function handleErrorOnEnableMicrophone(codeError: string) {
    // TODO Enviar o erro para o Application Insights
    // console.log(codeError)
    ModalMessage({
      title: 'Erro ao acessar o microfone',
      message: 'Ocorreu um erro ao tentar acessar o seu microfone!',
      buttonText: 'OK!'
    })

    setMicrophoneStatus('error')
  }

  function handleEnableMicrophone() {
    setMicrophoneStatus('success')
  }

  function handleMicrophoneStatus() {
    switch (microphoneStatus) {
      case 'error':
        return (
          <s.Balloon>
            <s.HorizontalContainer>
              <s.Message type="font11">Não foi possivel te ouvir</s.Message>
            </s.HorizontalContainer>

            <s.HorizontalContainer>
              <s.Message type="p2_regular">
                Se estiver enfrentando problemas com seu microfone, você pode
                testar com fone de ouvido.
              </s.Message>
            </s.HorizontalContainer>

            <s.HorizontalContainer>
              <s.Message type="font7" color="gray3">
                Ficou com alguma dúvida?{' '}
                <u
                  onClick={() =>
                    window.open(
                      'https://docwayhelp.zendesk.com/hc/pt-br',
                      '_blank'
                    )
                  }
                >
                  Acesse nosso FAQ
                </u>
              </s.Message>
            </s.HorizontalContainer>
          </s.Balloon>
        )
      case 'success':
        return (
          <s.Balloon>
            <s.HorizontalContainer>
              <s.Message type="font11">Te ouvimos muito bem</s.Message>
            </s.HorizontalContainer>

            <s.HorizontalContainer>
              <s.Message type="p2_regular">
                Dessa forma, a comunicação com seu médico será clara e
                eficiente.
              </s.Message>
            </s.HorizontalContainer>
          </s.Balloon>
        )
      default:
        return (
          <s.Balloon>
            <s.HorizontalContainer>
              <s.Icon name="microphone" />
              <s.Message type="font5">
                Fale conosco, precisamos te ouvir!
              </s.Message>
            </s.HorizontalContainer>

            <s.HorizontalContainer>
              <s.Icon name="speakerphone" />
              <s.Message type="font5">Procure um ambiente sem ruídos</s.Message>
            </s.HorizontalContainer>
          </s.Balloon>
        )
    }
  }

  function handleNext() {
    if (microphoneStatus === 'success') {
      setCurrentStep('CHECKLIST')
    }
  }

  return (
    <s.Page>
      <audio ref={audioRef} autoPlay={true} />
      <div>
        <s.Header>
          <BackIcon
            width={30}
            height={30}
            fill="white"
            onClick={() => setCurrentStep('INFORM_CHECK_MICROPHONE')}
          />
        </s.Header>
        <s.TestMessage type="font16">
          Vamos testar o seu microfone
        </s.TestMessage>
        <Waveform
          handleEnableMicrophone={handleEnableMicrophone}
          handleErrorOnEnableMicrophone={handleErrorOnEnableMicrophone}
        />
      </div>

      <s.Footer>
        {handleMicrophoneStatus()}
        <s.ButtonsContainer>
          <s.Check
            name="check"
            disabled={microphoneStatus !== 'success'}
            onClick={handleNext}
          />
        </s.ButtonsContainer>
      </s.Footer>
    </s.Page>
  )
}

export default CheckMicrophone
