import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'
import Text from 'components/Text'

import ButtonDefault from 'components/Button'

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  display: flex;
  flex-direction: column;
`

export const Content = styled(Container)`
  flex: 1;
  margin-top: 32px;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: flex-start;
`

export const Message = styled(Text)`
  margin-top: 16px;
`

export const InputContainer = styled.div`
  height: 280px;
  margin-top: 50px;
  div {
    margin-bottom: 50px;
  }
`

export const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
`

export const Button = styled(ButtonDefault)`
  width: 100%;
`
