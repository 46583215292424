import React from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'
import { ReactComponent as DefaultImage } from 'assets/images/teleconsulta.svg'

import * as s from './styles'


const AgeSorryInfo = () => {
  const history = useHistory()

  function handleBack() {
    history.push('/dashboard')
  }
  
  return (
    <>
      <s.Page>
        <s.Header>
          <BackIcon
            width={30}
            height={30}
            fill="gray"
            onClick={() => handleBack()}
          />
        </s.Header>

        <s.Content>

          <s.ImageContainer>
            <DefaultImage />
          </s.ImageContainer>

          <s.Subtitle>
            Atendimento médico para menores de idade
          </s.Subtitle>

          <s.Info>
          Informamos que, conforme regulamentação, pacientes menores de idade devem solicitar atendimento por meio dos pais ou responsáveis legais. 
          Por favor, peça para que eles solicitem o atendimento através da central telefônica no número <strong>11 3003-6921</strong>.
          </s.Info>

        </s.Content>

      </s.Page>
    </>
  )
}

export default AgeSorryInfo
