import styled from 'styled-components'
import { Container as ContainerBTS, Row } from 'styled-bootstrap-grid'

import ButtonDefault from 'components/Button'
import InputDefault from 'components/Input'

export const Page = styled(ContainerBTS)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  width: 100vw;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: flex-start;
  margin-bottom: 32px;
`

export const Input = styled(InputDefault)`
  margin-top: 45px;
`

export const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 32px;
`

export const Button = styled(ButtonDefault)`
  width: 100%;
`

export const ForgotPasswordContainer = styled(Row)`
  cursor: pointer;
  margin: 32px 0;

  span {
    text-decoration: underline;
  }
`
