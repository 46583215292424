import styled from 'styled-components'

export const Button = styled.button`
  background-color: ${props => props.theme.colors['white']};
  border: 0;
  border-top: 2px solid ${props => props.theme.colors.grayLight};
  border-radius: 8px;
  color: ${props => props.theme.colors['green']};
  font-size: 16px;
  font-weight: 500;
  outline: none;
  padding: 16px;
  width: 100%;
`
export const Support = styled.div`
  color: ${props => props.theme.colors['gray2']};
  font-size: 12px;
  width: 100%;
  a {
    text-decoration: underline;
  }
  button {
    margin-top: 32px;
  }
`
