import styled, { css } from 'styled-components'
import { animated } from 'react-spring'
import Icon from 'components/IconExporter'
import Text from 'components/Text'

interface IToastProps {
  type: 'success' | 'error'
}

const toastTypeVariations = {
  success: css`
    border-color: ${props => props.theme.colors.green3};
    box-shadow: green;
  `,
  error: css`
    border-color: red;
    box-shadow: red;
  `
}

export const Container = styled(animated.div)<IToastProps>`
  width: 310px;
  position: relative;
  padding: 14px;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  background-color: white;
  border: 1px solid;
  ${props => toastTypeVariations[props.type || 'success']};

  & + div {
    margin-top: 7px;
  }
`

export const IconStyled = styled(Icon)`
  width: 21px;
`

export const InfoContainer = styled.div`
  margin: 0 18px 0 12px;
`

export const DescriptionText = styled(Text)`
  margin-top: 5px;
`

export const CloseToastIconButton = styled(IconStyled)`
  position: absolute;
  right: 12px;
  cursor: pointer;
`
