import React, { createContext } from 'react'
import {
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalVideoTrack
} from 'twilio-video'

import useLocalTracks from './useLocalTracks'

export interface IVideoContext {
  localTracks: (LocalAudioTrack | LocalVideoTrack)[]
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions
  ) => Promise<LocalVideoTrack>
  createAudioAndVideoTracks: () => Promise<void>
}

export const VideoContext = createContext<IVideoContext>(null)

export function VideoProvider({ children }) {
  const {
    localTracks,
    getLocalAudioTrack,
    getLocalVideoTrack,
    createAudioAndVideoTracks
  } = useLocalTracks()

  return (
    <VideoContext.Provider
      value={{
        localTracks,
        getLocalAudioTrack,
        getLocalVideoTrack,
        createAudioAndVideoTracks
      }}
    >
      {children}
    </VideoContext.Provider>
  )
}
