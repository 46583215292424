import 'styled-components'
import { DefaultTheme } from 'styled-components'

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: { [key in keyof typeof themeColors]: string }
    fontTypes: { [key in keyof typeof fontTypes]: any }
    grid: { [key in keyof typeof gridConfig]: any }
  }
}

export const themeColors = {
  green: '#8fd16a',
  green1: '#a5da88',
  green2: '#bce3a6',
  green3: '#d2edc3',
  green4: '#e9f6e1',
  green5: '#8dce67',
  gray: '#707070',
  gray1: '#8d8d8d',
  gray2: '#a9a9a9',
  gray3: '#c6c6c6',
  gray4: '#e2e2e2',
  gray40: 'rgb(112, 112, 112, 0.6)',
  grayLight: '#f0f0f0',
  red: '#ce4e4e',
  red1: '#d87171',
  red2: '#e29595',
  red3: '#ebb8b8',
  red4: '#f5dcdc',
  orange: '#f7b500',
  yellow: '#f8dd50',
  yellow1: '#f9e473',
  yellow2: '#fbeb96',
  yellow3: '#fcf1b9',
  yellow4: '#fef8dc',
  white: '#FDFDFD',
  white2: '#FFF',
  white3: '#f4f4f4',
  blue3: '#bde5e9'
}

export const gridConfig = {
  gridColumns: 8,
  breakpoints: {
    xl: 1280,
    lg: 1280,
    md: 980,
    sm: 736,
    xs: 576
  },
  row: {
    padding: 8
  },
  col: {
    padding: 8
  },
  container: {
    padding: 16,
    maxWidth: {
      xl: 1280,
      lg: 1280,
      md: 980,
      sm: 736,
      xs: 576
    }
  }
}

export const fontTypes = {
  font1: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5
  },
  font2: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1
  },
  font3: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1
  },
  h2_semibold: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.17
  },
  h3_semibold: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.2
  },
  p4_regular: {
    fontSize: 12,
    lineHeight: 1.33
  },
  font5: {
    fontSize: 16,
    lineHeight: 1
  },
  font6: {
    fontSize: 14,
    lineHeight: 1.14
  },
  p2_regular: {
    fontSize: 16,
    lineHeight: 1.5
  },
  p2_medium: {
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 500
  },
  font7: {
    fontSize: 14,
    lineHeight: 1.43
  },
  font8: {
    fontSize: 12,
    lineHeight: 1
  },
  font9: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1
  },
  font10: {
    fontSize: 16,
    lineHeight: 1.5
  },
  font11: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5
  },
  font12: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 0.86
  },
  font13: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1
  },
  font14: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 0.86
  },
  p3_regular: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.43
  },
  font16: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.33
  },
  font17: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.14
  }
}

const theme: DefaultTheme = {
  colors: themeColors,
  fontTypes: fontTypes,
  grid: gridConfig
}

export default theme
