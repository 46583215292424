import styled from 'styled-components'

export const Textarea = styled.textarea`
  height: 100%;
  width: 100%;
  border-radius: 12px;
  border: 1px solid gray;
  font-size: 16px;
  padding: 10px;
  outline: none;
`
