import React, { useContext, useEffect, useRef, useState } from 'react'

import * as s from './styles'

import { LocalVideoTrack } from 'twilio-video'
import { Context } from 'hooks/BeforeAttendanceContext'
import { VideoContext } from 'hooks/LocalTracksContext'
import { useVideoInputDevices } from 'hooks/deviceHooks'
import useMediaStreamTrack from 'hooks/useMediaStreamTrack'

import ModalMessage from 'components/ModalMessage'
import { handleMediaErrors } from 'components/Room/utils'

const CheckCamera = () => {
  const { setCurrentStep } = useContext(Context)

  const { getLocalVideoTrack } = useContext(VideoContext)

  const videoRef = useRef()

  const [localVideoTrack, setLocalVideoTrack] = useState<LocalVideoTrack>(null)
  const videoInputDevices = useVideoInputDevices()

  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack)
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId

  useEffect(() => {
    getLocalVideoTrack()
      .then((localVideoTrack: LocalVideoTrack) => {
        setLocalVideoTrack(localVideoTrack)
      })
      .catch(error => {
        const mediaErrors = handleMediaErrors(error)

        ModalMessage({
          title: mediaErrors.title,
          message: mediaErrors.message,
          buttonText: 'OK!'
        })
      })
  }, [getLocalVideoTrack])

  useEffect(() => {
    if (localVideoTrack) {
      localVideoTrack.attach(videoRef.current)

      return () => {
        localVideoTrack.detach().forEach(element => element.remove())
        localVideoTrack.stop()
      }
    }
  }, [localVideoTrack])

  function handleChangeCamera() {
    let newLocalVideoInputDeviceId = ''

    for (
      let videoInput = 0;
      videoInput <= videoInputDevices.length - 1;
      videoInput++
    ) {
      if (videoInputDevices[videoInput].deviceId === localVideoInputDeviceId) {
        if (videoInput + 1 < videoInputDevices.length) {
          newLocalVideoInputDeviceId =
            videoInputDevices[videoInput + 1].deviceId
        } else {
          newLocalVideoInputDeviceId = videoInputDevices[0].deviceId
        }

        replaceTrack(newLocalVideoInputDeviceId)
        break
      }
    }
  }

  function replaceTrack(newDeviceId: string) {
    localVideoTrack.restart({
      width: 1280,
      height: 720,
      frameRate: 24,
      deviceId: { exact: newDeviceId }
    })
  }

  function handleNext() {
    setCurrentStep('INFORM_CHECK_MICROPHONE')
  }

  return (
    <s.Video>
      <video ref={videoRef} autoPlay={true} />

      <s.Page>
        <s.Footer>
          <s.Balloon>
            <s.HorizontalContainer>
              <s.Icon name="camera" />
              <s.Message type="font5">Conseguimos te ver bem?</s.Message>
            </s.HorizontalContainer>

            <s.HorizontalContainer>
              <s.Icon name="flash" />
              <s.Message type="font5">Fique em um local iluminado</s.Message>
            </s.HorizontalContainer>
          </s.Balloon>

          <s.ButtonsContainer>
            <s.Check name="check" onClick={handleNext} />

            {localVideoTrack && videoInputDevices.length > 1 && (
              <s.ChangeCamera name="change" onClick={handleChangeCamera} />
            )}
          </s.ButtonsContainer>
        </s.Footer>
      </s.Page>
    </s.Video>
  )
}

export default CheckCamera
