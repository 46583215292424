import React from 'react'

import AppProviders from 'AppProviders'
import GlobalStyles from 'styles/global'
import Routes from 'routes'

function App() {
  return (
    <AppProviders>
      <GlobalStyles />
      <Routes />
    </AppProviders>
  )
}

export default App
