import axios from 'axios'

import localStorage, { getUserAuth } from 'helpers/localStorage'
import history from 'services/history'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'content-type': 'application/json'
  }
})

const requestInterceptor = request => {
  const token = getUserAuth().accessToken
  if (token) request.headers.Authorization = `Bearer ${token}`
  return request
}

const responseInterceptor = response => response
const responseErrorInterceptor = error => {
  let newError = { ...error }
  if (error.response.status === 401) {
    newError = {
      ...error,
      response: {
        ...error.response,
        data: [
          ...error.response.data,
          {
            message: 'Sessão expirada. Faça login novamente.'
          }
        ]
      }
    }
    localStorage.removeUserAuth()
    history.push('/')
  }

  return Promise.reject(newError.response)
}

axiosInstance.interceptors.request.use(requestInterceptor)
axiosInstance.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor
)

export default axiosInstance
