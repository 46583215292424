import styled from 'styled-components'

import { Container } from 'styled-bootstrap-grid'
import IconExporter from 'components/IconExporter'
import Text from 'components/Text'

interface ICheckStyle {
  disabled: boolean
}

export const Page = styled(Container)`
  background-color: ${props => props.theme.colors.green};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: flex-start;
  margin-bottom: 60px;
`

export const TestMessage = styled(Text)`
  color: ${props => props.theme.colors.white};

  text-align: center;
`

export const Footer = styled.div``

export const Balloon = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;
  border-top-left-radius: unset;
  margin-bottom: 20px;
  padding: 12px 16px;
`

export const Icon = styled(IconExporter).attrs(() => ({
  color: 'gray'
}))`
  height: 24px;
  width: 24px;
`

export const Message = styled(Text)`
  margin-left: 8px;
`

export const HorizontalContainer = styled.div`
  align-items: center;
  display: flex;
`

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`

export const Check = styled(IconExporter).attrs(() => ({
  color: 'green'
}))<ICheckStyle>`
  background-color: ${props => props.theme.colors.white};
  border-radius: 50px;
  color: ${props => props.theme.colors.green};
  cursor: pointer;
  height: 60px;
  opacity: ${props => (props.disabled ? 0.2 : 1)};
  width: 60px;
`

export const Microphone = styled(IconExporter).attrs(() => ({
  color: 'white'
}))`
  cursor: pointer;
  height: 40px;
  width: 40px;
`
