import styled from 'styled-components'

import IconExporter from 'components/IconExporter'
import Text from 'components/Text'

export const Card = styled.div`
  border-radius: 12px;
  box-shadow: 0 1px 4px 0 ${props => props.theme.colors.gray40};
  display: flex;
  flex-direction: column;
  margin: 32px 16px;
  padding: 0 16px 20px;
`

export const AppointmentIcon = styled.img`
  height: 100px;
  position: relative;
  top: -30px;
`

export const DateAppointmentContainer = styled.div`
  display: flex;
`

export const DateAppointment = styled(Text)``

export const BrazilTimezoneMessage = styled(Text)`
  color: ${props => props.theme.colors.gray2};
  margin-left: 4px;
`

export const SpecialtyAndPatientName = styled(Text)`
  color: ${props => props.theme.colors.gray2};
  margin-top: 8px;
`

export const CancelAppointment = styled(Text)`
  color: ${props => props.theme.colors.gray2};
  margin-top: 20px;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const RoomAccessContainer = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.green};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  margin-top: 8px;
  padding: 4px 16px;
`

export const VideoIcon = styled(IconExporter).attrs(() => ({
  color: 'white'
}))`
  height: 20px;
  width: 20px;
`

export const RoomAccess = styled(Text)`
  color: ${props => props.theme.colors.white};
  margin-left: 8px;
`
