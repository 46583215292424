import styled from 'styled-components'

import { Carousel as ReactCarousel } from 'react-responsive-carousel'
import Text from 'components/Text'

export const Title = styled(Text)`
  margin: 32px 16px;
`

export const Carousel = styled(ReactCarousel)`
  .slide {
    background: ${props => props.theme.colors.white};
  }

  .dot {
    background: ${props => props.theme.colors.gray} !important;
  }

  max-width: 100vw;
`
