import React, { useState, MouseEvent } from 'react'
import { Props } from 'react-input-mask'

import * as s from './styles'

import { ReactComponent as EyeIcon } from 'assets/icons/ic-eye.svg'
import { ReactComponent as EyeSlashIcon } from 'assets/icons/ic-eye-slash.svg'

type PropsReactInputMask = Omit<Props, 'mask'>

interface IProps extends PropsReactInputMask {
  mask?: string
  label?: string
  hasError?: boolean
  errorMessage?: string | undefined
  type?: string
}

const Input: React.FC<IProps> = ({
  mask = '',
  label = '',
  hasError = false,
  id,
  value,
  className,
  errorMessage,
  type,
  ...rest
}) => {
  const [seePassword, setSeePassword] = useState(false)

  function showPassword(event: MouseEvent) {
    event.preventDefault()
    setSeePassword(!seePassword)
  }
  return (
    <s.Container
      hasError={hasError}
      hasContent={Boolean(value || rest.placeholder)}
      className={className}
    >
      <s.InputStyledMask
        {...rest}
        styled={{ hasError }}
        id={id}
        mask={mask}
        value={value}
        type={type === 'password' && !seePassword ? 'password' : 'text'}
      />
      <label htmlFor={id}>{label}</label>

      {type === 'password' && (
        <button onClick={showPassword}>
          {seePassword ? <EyeIcon /> : <EyeSlashIcon />}
        </button>
      )}

      <s.Stripe hasError={hasError} />
      {hasError && <s.ErrorMessage>{errorMessage}</s.ErrorMessage>}
    </s.Container>
  )
}

export default Input
