export function sendEvent(action: string, category: string): void {
  const ga = window as any
  ga.gtag('event', action, {
    event_category: category
  })
}

export function sendEventWithValue(
  action: string,
  category: string,
  value: string
): void {
  const ga = window as any
  ga.gtag('event', action, {
    event_category: category,
    value: value
  })
}
