import styled from 'styled-components'

export const Container = styled.div`
  padding: 12px 0px;
  background-color: ${props => props.theme.colors.red};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-height: 40px;
`

export const Text = styled.p`
  font-size: ${props => props.theme.fontTypes.font1.fontSize};
  font-weight: ${props => props.theme.fontTypes.font1.fontWeight};
  color: ${props => props.theme.colors.white};
`
