import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'
import { themeColors } from 'styles/theme'

export interface IStyledProps {
  color: keyof typeof themeColors
}

export const Page = styled.div`
  width: 100vw;
  height: 100vh;
`
export const Header = styled(Container)`
  position: relative;
  top: 20px;
  height: 64px;
  padding-left: 12px;
`

export const Body = styled(Container)<IStyledProps>`
  div {
    border-bottom: 1px solid ${({ theme, color }) => theme.colors[color]};
    height: 60px;
  }

  p {
    padding: 20px 0px 20px 10px;
    float: left;
  }
`
