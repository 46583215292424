import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'

import ButtonDefault from 'components/Button'

export const Page = styled.div`
  width: 100vw;
  height: 100vh;
`
export const Header = styled(Container)`
  position: relative;
  top: 20px;
  height: 64px;
  padding-left: 12px;
`

export const SubTitleContainer = styled(Container)`
  p {
    padding: 20px 0px 20px 0px;
  }
`
export const Body = styled(Container)`
  div {
    margin: 32px 0px 60px 0px;
  }
`

export const Footer = styled(Container)`
  position: absolute;
  bottom: 25px;
`

export const Button = styled(ButtonDefault)`
  width: 100%;
  margin-bottom: 12px;
`
