import styled, { keyframes } from 'styled-components'
import { themeColors } from 'styles/theme'

const animateEllipsisDot = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`

interface ContainerProps {
  color: keyof typeof themeColors
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100px;
  justify-content: space-between;

  div {
    animation: ${animateEllipsisDot} 1s infinite;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: ${({ color, theme }) => theme.colors[color]};

    :nth-child(1) {
      animation-delay: 0s;
    }
    :nth-child(2) {
      animation-delay: 0.1s;
    }
    :nth-child(3) {
      animation-delay: 0.2s;
    }
  }
`
