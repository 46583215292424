import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { validateEmail } from 'helpers/validators'

import * as s from './styles'
import Text from 'components/Text'

import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'
import localStorage from 'helpers/localStorage'
import { useMutate } from 'restful-react'

const Login = () => {
  const history = useHistory()
  const { appointmentId } = useParams() as any;

  const [email, setEmail] = useState({
    value: '',
    isValid: false,
    touched: false
  })
  const [password, setPassword] = useState({
    value: '',
    isValid: false,
    touched: false
  })

  const { mutate: getToken, loading } = useMutate('POST', 'user/connect/token')

  async function login() {
    const data = await getToken({
      username: email.value,
      password: password.value
    })

    localStorage.setUserAuth({
      accessToken: data.token,
      refreshToken: data.refresh_token,
      expiresIn: data.expires_in,
      tokenType: data.token_type
    })

    if (!appointmentId) {
      history.push('/dashboard')
    } else {
      history.push(`/antessala/${appointmentId}`)
    }
  }

  function validatePassword(password: string): boolean {
    return password.length > 0
  }

  return (
    <s.Page>
      <div>
        <s.Header>
          <BackIcon
            width={30}
            height={30}
            fill="gray"
            onClick={() => history.push('/')}
          />
        </s.Header>

        <Text type="h2_semibold">Entre, você já é de casa!</Text>

        <s.Input
          label="E-mail"
          value={email.value}
          onChange={({ target }) =>
            setEmail({ ...email, value: target.value.trim() })
          }
          onBlur={() =>
            setEmail({
              ...email,
              isValid: validateEmail(email.value),
              touched: true
            })
          }
          hasError={!email.isValid && email.touched}
          errorMessage="Email inválido. Digite um email válido."
        />

        <s.Input
          label="Senha"
          type="password"
          value={password.value}
          onChange={({ target }) =>
            setPassword({ ...password, value: target.value })
          }
          onBlur={() =>
            setPassword({
              ...password,
              isValid: validatePassword(email.value),
              touched: true
            })
          }
          hasError={!password.isValid && password.touched}
          errorMessage="Senha inválida. Digite uma email válida."
        />

        <s.ForgotPasswordContainer>
          <Text type="p4_regular" color="gray2">
            Esqueceu sua senha?{' '}
            <span onClick={() => history.push('/esqueceu-sua-senha')}>
              Clique aqui
            </span>
          </Text>
        </s.ForgotPasswordContainer>
      </div>

      <s.Footer>
        <s.Button
          color="green"
          disabled={!email.isValid && !password.isValid}
          isLoading={loading}
          onClick={login}
        >
          Entrar
        </s.Button>
      </s.Footer>
    </s.Page>
  )
}

export default Login
