import React, { useContext } from 'react'

import {
  CreateAttendanceContext,
  STEPS_ENUM
} from 'hooks/CreateAttendanceContext'

import Symptoms from './Symptoms'
import DateHour from './DateHour'

type STEPPER = { [key in STEPS_ENUM]: React.ReactElement }

const steps: STEPPER = {
  SYMPTOMS: <Symptoms />,
  DATE_HOUR: <DateHour />
}

const Steps = () => {
  const { currentStep } = useContext(CreateAttendanceContext)
  return steps[currentStep]
}

export default Steps
