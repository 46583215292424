import React from 'react'
import * as s from './styles'
import TextField from '@material-ui/core/TextField'

interface IProps {
  getUserAppointments: (selectedDate: string) => void
}

const DateFilter: React.FC<IProps> = ({ getUserAppointments }) => {
  return (
    <s.Container>
      <s.MonthPicker>
        <TextField
          type="month"
          InputLabelProps={{
            shrink: true
          }}
          onChange={event => {
            getUserAppointments(
              event.target.value === '' ? null : `${event.target.value}-01`
            )
          }}
          onKeyDown={event => {
            event.preventDefault()
          }}
        />
      </s.MonthPicker>
    </s.Container>
  )
}

export default DateFilter
