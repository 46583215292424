import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Text from 'components/Text'

import * as s from './styles'
import DateFilter from './DateFilter'
import AppointmentItem from './AppointmentItem'
import Appointment from 'types/Appointment'
import SpinnerLoading from 'components/SpinnerLoading'
import { useToast } from 'hooks/toast'
import { ConnectivityContext } from 'hooks/ConnectivityContext'
import OfflineBar from 'components/OfflineBar'
import { useMutate } from 'restful-react'

const Historic = () => {
  const history = useHistory()
  const [appointments, setAppointments] = useState<Appointment[]>([])
  const [displayInfo, setDisplayInfo] = useState(true)
  const { addToast } = useToast()

  const { isOnline } = useContext(ConnectivityContext)

  const { loading, mutate: getHistory } = useMutate(
    'POST',
    'appointment/history'
  )

  const getUserAppointments = useCallback(
    async selectedDate => {
      setDisplayInfo(selectedDate === null)

      const data = await getHistory({
        startDate: selectedDate
      })

      setAppointments(data)
    },
    [addToast]
  )

  useEffect(() => {
    getUserAppointments(null)
  }, [getUserAppointments])

  function handleDetails(id) {
    history.push(`/historico/${id}`)
  }

  return (
    <>
      <OfflineBar />
      <s.Page>
        <s.Title type="font9">Atendimentos</s.Title>

        <DateFilter
          getUserAppointments={isOnline ? getUserAppointments : () => null}
        />
        {loading ? (
          <SpinnerLoading />
        ) : (
          <div>
            {displayInfo && (
              <Text type="font11">
                Acompanhe abaixo o seu histórico de consultas dos últimos 6
                meses
              </Text>
            )}
            {appointments.length > 0 &&
              appointments?.map(appointment => (
                <AppointmentItem
                  key={appointment.id}
                  appointment={appointment}
                  onClick={() =>
                    appointment.status === 7 && isOnline
                      ? handleDetails(appointment.id)
                      : null
                  }
                />
              ))}
          </div>
        )}
      </s.Page>
    </>
  )
}

export default Historic
