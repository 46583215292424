import styled from 'styled-components'
import InputMask from 'react-input-mask'
import Text from 'components/Text'

interface IPropsStripe {
  hasError: boolean
}

export const Stripe = styled.span<IPropsStripe>`
  background-color: ${({ hasError, theme }) => {
    if (hasError) {
      return 'red'
    }
    return theme.colors.green
  }};
  display: block;
  height: 2px;
  left: 50%;
  position: relative;
  top: 1px;
  transition: 0.25s ease-in-out;
  width: 0%;
`

export const Container = styled.div<{
  hasContent: boolean
  hasError: boolean
}>`
  position: relative;
  border-bottom: 1px solid
    ${({ hasError, theme }) => (hasError ? theme.colors.red : '#ccc')};
  width: 100%;
  label {
    color: ${({ hasError }) => {
      if (hasError) {
        return 'red'
      }
      return 'initial'
    }};
    display: block;
    opacity: 0.6;
    pointer-events: none;
    position: absolute;
    transition: 0.25s ease-in-out;
    bottom: ${props => (props.hasContent ? '30px' : '4px')};
    ${({ theme }) => theme.fontTypes.font6};
  }

  button {
    background: transparent;
    border: 0;
    height: 20px;
    outline: none;
    position: absolute;
    right: 4px;
    width: 20px;
  }
`

interface IPropsInputStyledMask {
  styled: {
    hasError: boolean
  }
}

export const InputStyledMask = styled(InputMask)<IPropsInputStyledMask>`
  outline: none;
  border: none;
  width: 100%;

  ${({ theme }) => theme.fontTypes.p2_regular};

  &:focus {
    & ~ ${Stripe} {
      left: 0;
      width: 100%;
    }
    & ~ label {
      color: ${({ styled, theme }) =>
        styled.hasError ? theme.colors.red : theme.colors.green};
      bottom: 30px;
    }
  }
`

export const ErrorMessage = styled(Text).attrs(() => ({
  type: 'p4_regular',
  color: 'red'
}))`
  margin-top: 6px;
  position: absolute;
`
