import React, { useRef, useState, useContext } from 'react'

import axios from 'services/api'

import Text from 'components/Text'
import ButtonNext from 'components/ButtonNext'
import SpinnerLoading from 'components/SpinnerLoading'

import { Context } from 'hooks/AccountValidation'
import { useToast } from 'hooks/toast'

import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'

import * as s from './styles'

const ConfirmationCode = () => {
  const inputRef1 = useRef(null)
  const inputRef2 = useRef(null)
  const inputRef3 = useRef(null)
  const inputRef4 = useRef(null)

  const [input1, setInput1] = useState('')
  const [input2, setInput2] = useState('')
  const [input3, setInput3] = useState('')
  const [input4, setInput4] = useState('')
  const [loading, setLoading] = useState(false)

  const { addToast } = useToast()
  const { setCurrentStep, formData, setFormData } = useContext(Context)

  function allowNumbersOnly(e: React.KeyboardEvent) {
    const code = e.which ? e.which : e.keyCode
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault()
    }
  }

  async function handleResendCode() {
    setLoading(true)
    try {
      await axios.post('/user/resendcode', {
        email: formData.email,
        // name: formData.name
      })

      addToast({
        title: 'Código enviado.',
        description:
          'Aguarde alguns instantes até o recebimento do código em seu e-mail',
        type: 'success'
      })
    } catch (error) {
      addToast({
        title: 'Falha ao enviar o código.',
        description: 'Tente novamente.',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <s.Header>
        <BackIcon
          width={30}
          height={30}
          fill="white"
          onClick={() => setCurrentStep('EMAIL')}
        />
      </s.Header>
      <s.Container>
        <s.Content>
          <Text type="font2">Insira o código</Text>
          <br />
          <Text type="p2_regular">
            Enviamos o código de verificação de 4 dígitos para {formData.email}.
          </Text>
          <s.ContainerInputs>
            <input
              ref={inputRef1}
              value={input1}
              type="text"
              maxLength={1}
              onKeyPress={allowNumbersOnly}
              onChange={({ target }) => {
                setInput1(target.value)
                inputRef2.current.focus()
              }}
            />
            <input
              ref={inputRef2}
              value={input2}
              type="text"
              maxLength={1}
              onKeyPress={allowNumbersOnly}
              onChange={({ target }) => {
                setInput2(target.value)
                inputRef3.current.focus()
              }}
            />
            <input
              ref={inputRef3}
              value={input3}
              type="text"
              maxLength={1}
              onKeyPress={allowNumbersOnly}
              onChange={({ target }) => {
                setInput3(target.value)
                inputRef4.current.focus()
              }}
            />
            <input
              ref={inputRef4}
              value={input4}
              type="text"
              maxLength={1}
              onKeyPress={allowNumbersOnly}
              onChange={({ target }) => {
                setInput4(target.value)
              }}
            />
          </s.ContainerInputs>
        </s.Content>
        <s.Footer>
          <div>
            {loading ? (
              <s.LoadingContainer>
                <Text color="gray2" type="font8">
                  Enviando...
                </Text>
                <SpinnerLoading />
              </s.LoadingContainer>
            ) : (
              <Text color="gray2" type="font8">
                Ainda não recebeu?{' '}
                <u onClick={handleResendCode}>Reenviar novo código</u>
              </Text>
            )}
          </div>
          <ButtonNext
            disabled={!input1 || !input2 || !input3 || !input4}
            onClick={() => {
              setFormData({
                ...formData,
                code: `${input1}${input2}${input3}${input4}`
              })
              setCurrentStep('DEFINE_PASSWORD')
            }}
          />
        </s.Footer>
      </s.Container>
    </>
  )
}

export default ConfirmationCode
