import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'services/api'

import Appointment from 'types/Appointment'
import AppointmentItem from '../AppointmentItem'

import * as s from './styles'
import ModalConfirm from 'components/ModalConfirm'
import ModalMessage from 'components/ModalMessage'
import SpinnerLoading from 'components/SpinnerLoading'

import { useToast } from 'hooks/toast'

interface IProps {
  appointments: Appointment[]
  onChangeAppointments: (appointments: Appointment[] | []) => void
}

const Appointments: React.FC<IProps> = ({
  appointments,
  onChangeAppointments
}) => {
  const history = useHistory()
  const { addToast } = useToast()
  const [isLoading, setLoading] = useState(false)

  function handleCancel(appointmentId: string) {
    ModalConfirm({
      title: 'Tem certeza que deseja cancelar?',
      message:
        'Não se preocupe, caso precise você pode agendar outro em seguida.',
      handleConfirm: () => {
        cancelAppointment(appointmentId)
      }
    })
  }

  function cancelAppointment(appointmentId: string) {
    setLoading(true)
    axios
      .post(`appointment/${appointmentId}/cancel`)
      .then(() => {
        ModalMessage({
          title: 'Atendimento cancelado!',
          message: 'Seu agendamento foi cancelado com sucesso.',
          buttonText: 'OK!'
        })
        onChangeAppointments([
          ...appointments.filter(
            appointment => appointment.id !== appointmentId
          )
        ])
      })
      .catch(() => {
        addToast({
          description: 'Um erro ocorreu. Tente novamente.',
          type: 'error',
          title: 'Falha na requisição.'
        })
      })
      .finally(() => setLoading(false))
  }

  function handleAccessRoom(appointmentId: string) {
    history.push(`antessala/${appointmentId}`)
  }

  return isLoading ? (
    <SpinnerLoading></SpinnerLoading>
  ) : (
    <div>
      <s.Title type="h3_semibold">
        Oba! Acompanhe o status do seu atendimento.
      </s.Title>
      {appointments.length > 1 ? (
        <s.Carousel
          renderArrowNext={() => null}
          renderArrowPrev={() => null}
          showThumbs={false}
          showStatus={false}
          infiniteLoop
        >
          {appointments.map(appointment => (
            <AppointmentItem
              key={appointment.id}
              appointment={appointment}
              handleAccessRoom={handleAccessRoom}
              handleCancel={handleCancel}
            />
          ))}
        </s.Carousel>
      ) : (
        appointments.map(appointment => (
          <AppointmentItem
            key={appointment.id}
            appointment={appointment}
            handleAccessRoom={handleAccessRoom}
            handleCancel={handleCancel}
          />
        ))
      )}
    </div>
  )
}

export default Appointments
