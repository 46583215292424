import React from 'react'

import * as s from './styles'

import Appointment from 'types/Appointment'
import Moment from 'moment'
interface IProps {
  appointment: Appointment
  onClick?: () => void
}

const AppointmentItem: React.FC<IProps> = ({ appointment, onClick }) => {
  function getStatusTitle(status: number): string {
    switch (status) {
      case 8:
        return 'Cancelado pelo sistema'
      case 9:
        return 'Pré agendamento'
      case 10:
        return 'Cancelado pelo paciente'
      case 11:
        return 'Cancelado pelo médico'
      case 12:
        return 'Ausência'
      default:
        return 'Finalizado'
    }
  }

  return (
    <>
      <s.Container
        onClick={() => {
          if (onClick) onClick()
        }}
      >
        {appointment.doctor?.profilePicture ? (
          <s.DoctorPhoto src={appointment.doctor.profilePicture} alt="Doutor" />
        ) : (
          <s.PeopleContainer backgroundColor={'gray3'}>
            <s.People />
          </s.PeopleContainer>
        )}

        <s.InfoContainer>
          <s.DoctorName color={appointment.status === 7 ? 'gray' : 'gray2'}>
            {appointment.doctor
              ? `${appointment.doctor?.firstName} ${appointment.doctor?.lastName}`
              : `Medico não vinculado`}
          </s.DoctorName>

          <s.DateAppointment
            type="font5"
            color={appointment.status === 7 ? 'gray' : 'gray2'}
          >
            {Moment(appointment.dateAppointment).format('DD/MM/YYYY')} às{' '}
            {Moment(appointment.dateAppointment).format('HH:mm')}
          </s.DateAppointment>

          <s.StatusContainer status={appointment.status}>
            <s.VideoIcon name="video" status={appointment.status} />
            <s.Status type="font12" status={appointment.status}>
              {getStatusTitle(appointment.status)}
            </s.Status>
          </s.StatusContainer>
        </s.InfoContainer>

        {appointment.status === 7 && <s.Arrow />}
      </s.Container>

      <s.Divider />
    </>
  )
}

export default AppointmentItem
