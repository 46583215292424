import React, { useState, useEffect } from 'react'
import Text from 'components/Text'
import Input from 'components/Input'
import { useHistory } from 'react-router-dom'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'
import { validatePassword } from 'helpers/validators'
import ModalMessage from 'components/ModalMessage'
import axios from 'services/api'
import qs from 'querystring'

import * as s from './styles'

const NewPassword = () => {
  const history = useHistory()
  const [isLoading, setLoading] = useState(false)

  const [newPassword, setNewPassword] = useState({
    value: '',
    isValid: false,
    touched: false
  })

  const [token, setToken] = useState({
    value: ''
  })

  const [email, setEmail] = useState({
    value: ''
  })

  useEffect(() => {
    function getToken() {
      const queried = qs.parse(history.location.search)
      return queried['?token'] as string
    }

    function isTokenValid() {
      const encodedToken = getToken()
      if (encodedToken === undefined) {
        return false
      } else {
        const decodedToken = atob(encodedToken).split(';')
        if (
          decodedToken.length < 2 ||
          decodedToken[0] === '' ||
          decodedToken[1] === ''
        ) {
          return false
        } else {
          setEmail({ value: decodedToken[0] })
          setToken({ value: decodedToken[1].split('Â').join('') })
          return true
        }
      }
    }

    if (!isTokenValid()) history.push('/login')
  }, [token.value, history])

  function handleBack() {
    history.push('/login')
  }

  function generateNewPassword() {
    setLoading(true)
    axios
      .post('/user/resetpassword', {
        email: email.value,
        token: token.value,
        password: newPassword.value
      })
      .then(() =>
        ModalMessage({
          title: 'Senha alterada!',
          message: 'Agora é só fazer o login.',
          onClose: () => history.push('/login'),
          buttonText: 'OK!'
        })
      )
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <s.Page>
      <s.Header>
        <BackIcon
          width={30}
          height={30}
          fill="gray"
          onClick={() => handleBack()}
        />
      </s.Header>
      <s.SubTitleContainer>
        <Text type={'h2_semibold'}>Digite sua nova senha</Text>

        <Text type="font5">
          Por motivos de segurança sua nova senha não pode ser igual à anterior.
        </Text>
      </s.SubTitleContainer>
      <s.Body>
        <Input
          type="password"
          label="Nova senha"
          value={newPassword.value}
          alwaysShowMask={false}
          onChange={({ target }) =>
            setNewPassword({ ...newPassword, value: target.value })
          }
          onBlur={() => {
            setNewPassword({
              ...newPassword,
              isValid: validatePassword(newPassword.value),
              touched: true
            })
          }}
          hasError={!newPassword.isValid && newPassword.touched}
          errorMessage="A senha deve conter os requisitos necessários."
        />

        <Text type="font7" color="gray2">
          No mínimo 10 caracteres, sendo:
        </Text>
        <Text type="font7" color="gray2">
          • Caractere alfabético maiúsculo e minúsculo
        </Text>
        <Text type="font7" color="gray2">
          • Caractere numérico
        </Text>
        <Text type="font7" color="gray2">
          • Caractere especial
        </Text>
      </s.Body>

      <s.Footer>
        <s.Button
          color="green"
          disabled={!newPassword.isValid}
          isLoading={isLoading}
          onClick={generateNewPassword}
        >
          Salvar
        </s.Button>
      </s.Footer>
    </s.Page>
  )
}

export default NewPassword
