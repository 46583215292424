import styled from 'styled-components'

import Text from 'components/Text'

export const Page = styled.div`
  padding: 0 16px 48px;
  .MuiFormControl-root {
    width: 100%;
  }
`

export const Title = styled(Text)`
  padding: 16px 0;
  text-align: center;
`
