import React from 'react'

import * as s from './styles'
import { Symptom } from 'types/Appointment'

interface IProps {
  symptoms: Symptom[]
}

const Symptoms: React.FC<IProps> = ({ symptoms }) => {
  return (
    <s.SymptomsContainer>
      {symptoms.map(symptom => (
        <s.Symptom key={`${symptom.id} ${symptom.name}`} type="font14">
          {symptom.name}
        </s.Symptom>
      ))}
    </s.SymptomsContainer>
  )
}

export default Symptoms
