import React, { useContext, useEffect } from 'react'

import { Context, STEPS_ENUM } from 'hooks/AccountValidation'

// import NameValidation from './Questions/NameValidation'
// import BirthdateValidation from './Questions/BirthdateValidation'
// import CardNumberValidation from './Questions/CardNumberValidation'
import DefineEmail from './DefineEmail'
import ConfirmationCode from './ConfirmationCode'
import DefinePassword from './DefinePassword'
import PhoneNumberRegistration from './PhoneNumberRegistration'
import BlockScreen3Times from './BlockScreen3Times'
import axiosInstance from 'services/api'

type STEPPER = { [key in STEPS_ENUM]: React.ReactElement }

const steps: STEPPER = {
  PHONE_NUMBER: <PhoneNumberRegistration />,
  // NAME_QUESTION: <NameValidation />,
  // BIRTHDATE_QUESTION: <BirthdateValidation />,
  // CARDNUMBER_QUESTION: <CardNumberValidation />,
  EMAIL: <DefineEmail />,
  CONFIRMATION_CODE: <ConfirmationCode />,
  DEFINE_PASSWORD: <DefinePassword />
}

const Steps = () => {
  const { currentStep, attempts, formData } = useContext(Context)

  useEffect(() => {
    if (attempts === 3) {
      axiosInstance.post('/user/blacklist', {
        cpf: formData.cpf
      })
    }
  }, [attempts, formData.cpf])

  if (attempts === 3) {
    return <BlockScreen3Times />
  }
  return steps[currentStep]
}

export default Steps
