import React, { useContext } from 'react'
import { themeColors } from 'styles/theme'
import { ThemeContext } from 'styled-components'

import { IconAnimated } from './styles'

interface IProps {
  color?: keyof typeof themeColors
  width?: number
}

const SpinnerLoading = ({ color = 'green', width = 28, ...rest }: IProps) => {
  const theme = useContext(ThemeContext)
  return (
    <IconAnimated
      name="spinner"
      fill={theme.colors[color]}
      width={width}
      {...rest}
    />
  )
}

export default SpinnerLoading
