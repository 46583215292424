import React, { useContext, useEffect, useState } from 'react'

import IconExporter from 'components/IconExporter'
import Text from 'components/Text'

import { Context } from 'hooks/AccountValidation'

import * as s from './styles'

const WaitScreen = () => {
  const [timer, setTimer] = useState(10)
  const { setCurrentStep } = useContext(Context)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prev => prev - 1)
    }, 1000)

    if (timer < 1) {
      clearInterval(interval)
    }

    return () => {
      clearInterval(interval)
    }
  }, [timer])

  let timerText: string

  if (timer > 1) {
    timerText = `${timer} segundos`
  } else if (timer === 1) {
    timerText = `${timer} segundo`
  } else {
    timerText = `Ok!`
  }

  const canProceedRegister = timer === 0

  return (
    <s.Container>
      <s.Header>
        <IconExporter
          name="arrowleft"
          width={30}
          height={30}
          color="gray"
          onClick={() => {
            if (canProceedRegister) {
              setCurrentStep('PHONE_NUMBER')
              // setCurrentStep('NAME_QUESTION')
            }
          }}
        />
      </s.Header>
      <s.Content>
        <s.Card>
          <s.CardContainer>
            <Text type="h3_semibold" textAlign="center">
              Ops, validação incorreta!
            </Text>
            <br />
            <Text textAlign="center" color="gray2">
              Os dados informados não correspondem aos informados pelo parceiro
              em nossa base! Por motivos de segurança, você não pode prosseguir.
              Aguarde 10 segundos para tentar novamente!
            </Text>
          </s.CardContainer>
          <s.CardFooter
            onClick={() => {
              if (canProceedRegister) {
                setCurrentStep('EMAIL')
                // setCurrentStep('NAME_QUESTION')
              }
            }}
          >
            <Text color="green">{timerText}</Text>
          </s.CardFooter>
        </s.Card>
      </s.Content>
    </s.Container>
  )
}

export default WaitScreen
