import styled, { css } from 'styled-components'
import { themeColors } from 'styles/theme'

export interface IStyledProps {
  color: keyof typeof themeColors
  variant: 'raised' | 'contained'
}

function handleColor(
  disabled: boolean | undefined,
  color: keyof typeof themeColors,
  colors: { [key in keyof typeof themeColors]: string }
) {
  if (disabled) {
    return colors.gray3
  } else {
    return colors[color]
  }
}

export const Container = styled.button<IStyledProps>`
  ${({ theme, color, variant, disabled }) => css`
    ${theme.fontTypes.font2};
    height: 40px;
    border-radius: 12px;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 35px;
    outline: none;
    cursor: pointer;

    background: ${variant === 'raised' ? 'transparent' : theme.colors[color]};
    border: 1px solid ${handleColor(disabled, color, theme.colors)};
    color: ${handleColor(disabled, color, theme.colors)};

    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: all 0.4s ease-out;

    &:hover {
      &:active {
        pointer-events: ${disabled ? 'none' : 'auto'};

        &:after {
          background-size: 100%;
          opacity: 0.2;
          transition: all ease 0s;
        }
      }

      /* only render hover and ripple style if button is not disabled */
      ${disabled &&
      `
      &:before {
        width: 100%;
      }
      &:after {
        background: transparent
          radial-gradient(
            circle,
            black 1%,
            transparent 1%
          )
          center/15000%;
        background-position: center;
        content: '';
        height: 100%;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: all ease 0.8s;
        width: 100%;
      }
    `}
    }
    &:before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: width 0.2s ease-in;
      width: 0;
    }
  `}
`
