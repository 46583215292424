import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'

export const Page = styled(Container)``

export const Header = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  padding-top: 32px;
`

export const Item = styled.div`
  border-radius: 12px;
  box-shadow: 0 1px 4px 0 ${({ theme }) => theme.colors.gray40};
  height: 64px;
  width: 100%;
  padding-left: 16px;
  margin-top: 16px;
  font-weight: 500;

  display: flex;
  align-items: center;
`
