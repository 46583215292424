import styled from 'styled-components'

export const Container = styled.label`
  position: relative;
  cursor: pointer;
  height: 16px;
  width: 16px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    :checked ~ span {
      background-color: ${({ theme }) => theme.colors.green};
      -webkit-transform: rotate(0deg) scale(1);
      -ms-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      border: 2px solid ${({ theme }) => theme.colors.green};
    }

    :checked ~ span::after {
      -webkit-transform: rotate(45deg) scale(1);
      -ms-transform: rotate(45deg) scale(1);
      transform: rotate(45deg) scale(1);
      opacity: 1;
      left: 4px;
      top: 0px;
      width: 5px;
      height: 9px;
      border: solid ${({ theme }) => theme.colors.white};
      border-width: 0 2px 2px 0;
      background-color: transparent;
      border-radius: 0;
    }
  }

  span {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 16px;
    width: 16px;
    border: 2px solid #bbbbbb;
    background-color: white;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;

    :after {
      position: absolute;
      content: '';
      left: 12px;
      top: 12px;
      height: 0px;
      width: 0px;
      border-radius: 5px;
      border: solid ${({ theme }) => theme.colors.white};
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(0deg) scale(0);
      -ms-transform: rotate(0deg) scale(0);
      transform: rotate(0deg) scale(0);
      opacity: 1;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
    }
  }
`
