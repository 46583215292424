import React from 'react'
import { useHistory } from 'react-router-dom'

import IconExporter from 'components/IconExporter'
import Text from 'components/Text'

import * as s from './styles'

const BlockScreen3Times = () => {
  const history = useHistory()

  function redirectToFAQ() {
    window.open('https://docwayhelp.zendesk.com/hc/pt-br', '_blank')
  }
  return (
    <s.Container>
      <s.Header>
        <IconExporter
          name="arrowleft"
          width={30}
          height={30}
          color="gray"
          onClick={() => {
            history.push('/')
          }}
        />
      </s.Header>
      <s.Content>
        <s.Card>
          <s.CardContainer>
            <Text type="h3_semibold" textAlign="center">
              Ops, validação incorreta!
            </Text>
            <br />
            <Text textAlign="center" color="gray2">
              Encontramos erros consecutivos na validação dos seus dados! Por
              motivos de segurança, você não pode prosseguir. Entre em contato
              com o nosso suporte.
            </Text>
            <br />
            <Text type="font8" color="gray2" textAlign="center">
              Ficou com alguma dúvida?{' '}
              <u onClick={redirectToFAQ}>Acesse nosso FAQ</u>
            </Text>
          </s.CardContainer>
          <s.CardFooter
            onClick={() => {
              history.push('/')
            }}
          >
            <Text color="green">Entendi!</Text>
          </s.CardFooter>
        </s.Card>
      </s.Content>
    </s.Container>
  )
}

export default BlockScreen3Times
