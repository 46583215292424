import { ConnectivityContext } from 'hooks/ConnectivityContext'
import React, { useContext, VoidFunctionComponent } from 'react'
import { Container, Text } from './styles'

const OfflineBar: VoidFunctionComponent = () => {
  const { isOnline, checkStatus } = useContext(ConnectivityContext)

  if (!isOnline) {
    return (
      <Container onClick={() => checkStatus()}>
        <Text>Você ficou offline</Text>
      </Container>
    )
  }

  return null
}

export default OfflineBar
