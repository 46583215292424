import React, { useState, useContext } from 'react'

import axios from 'services/api'

import Text from 'components/Text'
import ButtonNext from 'components/ButtonNext'
import Input from 'components/Input'

import { Context } from 'hooks/AccountValidation'
import { useToast } from 'hooks/toast'

import { validateEmail } from 'helpers/validators'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'

import * as s from './styles'

const DefineEmail = () => {
  const { setCurrentStep, formData, setFormData } = useContext(Context)
  const [email, setEmail] = useState({
    value: '',
    isValid: false,
    touched: false
  })

  const [loading, setLoading] = useState(false)

  const { addToast } = useToast()

  async function handleSendCode() {
    setLoading(true)
    try {
      await axios.post('/user/code', {
        email: email.value,
        name: ''
      })
      setFormData({ ...formData, email: email.value })
      setLoading(false)
      setCurrentStep('CONFIRMATION_CODE')
    } catch (error) {
      setLoading(false)
      addToast({
        title: '',
        description: '',
        type: 'error'
      })
    }
  }

  return (
    <s.Page>
      <s.Header>
        <s.ContainerTop>
          <BackIcon
            width={30}
            height={30}
            fill="white"
            onClick={() => {
              setCurrentStep('PHONE_NUMBER') // setCurrentStep('CARDNUMBER_QUESTION')
            }}
          />
        </s.ContainerTop>
        <s.HeaderDescriptionContainer>
          <Text color="white" type="h2_semibold">
            Insira o seu e-mail
          </Text>
        </s.HeaderDescriptionContainer>
      </s.Header>
      <s.Content>
        <Input
          label="E-mail"
          value={email.value}
          onChange={({ target }) => {
            setEmail({
              ...email,
              value: target.value,
              isValid: validateEmail(target.value),
              touched: true
            })
          }}
          hasError={!email.isValid && email.touched}
          errorMessage="Digite um e-mail válido"
        />
        <s.FooterContainer>
          <ButtonNext
            isLoading={loading}
            disabled={!email.isValid}
            onClick={handleSendCode}
          />
        </s.FooterContainer>
      </s.Content>
    </s.Page>
  )
}

export default DefineEmail
