import React, { useState, useEffect } from 'react'
import Appointment from 'types/Appointment'

import * as s from './styles'
import AppointmentIcon from 'assets/icons/ic_appointment.svg'

import moment from 'moment'
import momentTimezone from 'moment-timezone'

interface IProps {
  appointment: Appointment
  handleAccessRoom: (appointmentId: string) => void
  handleCancel: (appointmentId: string) => void
}

const AppointmentItem: React.FC<IProps> = ({
  appointment,
  handleAccessRoom,
  handleCancel
}) => {
  const [isReadyToAccessRoom, setIsReadyToAccessRoom] = useState(false)

  useEffect(() => {
    setInterval(() => {
      function calcTimeoutToCancel() {
        const date = new Date(appointment.dateAppointment)

        /* Devido não termos implementado a captura da localização do usuario,
        está sendo verificado os minutos restantes com base no horário de Brasilia */
        let diffInMs =
          date.getTime() -
          new Date(
            momentTimezone(new Date())
              .tz('America/Sao_Paulo')
              .format()
              .substr(0, 19)
          ).getTime()

        if (diffInMs > 0) {
          diffInMs = diffInMs / 1000

          return diffInMs
        }

        return 0
      }

      const timeoutToCancel = calcTimeoutToCancel()

      let hours = 0
      let minutes = 0

      if (timeoutToCancel > 0) {
        minutes = Math.floor((timeoutToCancel / 60) % 60)
        hours = Math.floor((timeoutToCancel / 60 / 60) % 24)
      }

      if (hours === 0 && minutes < 5) {
        setIsReadyToAccessRoom(true)
      }
    }, 1000)
  }, [appointment.dateAppointment])

  return (
    <s.Card>
      <s.AppointmentIcon src={AppointmentIcon} />
      <s.DateAppointmentContainer>
        <s.DateAppointment type="font2">
          {moment(appointment.dateAppointment).format('DD/MM/YYYY')} às{' '}
          {moment(appointment.dateAppointment).format('HH:mm')}
        </s.DateAppointment>
        <s.BrazilTimezoneMessage type="p3_regular">
          (Horário de Brasília)
        </s.BrazilTimezoneMessage>
      </s.DateAppointmentContainer>

      <s.SpecialtyAndPatientName type="font5">
        {appointment.specialty.name} • Para {appointment.requester.firstName}
      </s.SpecialtyAndPatientName>

      {isReadyToAccessRoom ? (
        <s.RoomAccessContainer onClick={() => handleAccessRoom(appointment.id)}>
          <s.VideoIcon name="video" />
          <s.RoomAccess type="font17">Sala de atendimento aberta</s.RoomAccess>
        </s.RoomAccessContainer>
      ) : (
        <s.CancelAppointment type="font8">
          Aconteceu um imprevisto?{' '}
          <span onClick={() => handleCancel(appointment.id)}>
            Cancelar o atendimento
          </span>
        </s.CancelAppointment>
      )}
    </s.Card>
  )
}

export default AppointmentItem
