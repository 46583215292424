import styled from 'styled-components'
import Button from 'components/Button'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 12px 16px 40px;
  width: 100%;
`

export const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const CloseIconButton = styled.div`
  height: 32px;
  width: 32px;
  display: grid;
  place-items: center;
`

export const TextAreaContainer = styled.div`
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const ButtonStyled = styled(Button)`
  margin-top: 20px;
  width: 100%;
`
export const RescheduleContainer = styled.div`
  margin-top: 24px;
  font-weight: 500;
  p {
    text-align: center;
  }
`
