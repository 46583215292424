import React, { useContext, useState } from 'react'
import Text from 'components/Text'
import Input from 'components/Input'

import { useHistory } from 'react-router-dom'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'
import { validatePassword } from 'helpers/validators'
import { useToast } from 'hooks/toast'

import axios from 'services/api'
import ModalMessage from 'components/ModalMessage'

import * as s from './styles'
import OfflineBar from 'components/OfflineBar'
import { ConnectivityContext } from 'hooks/ConnectivityContext'
import { Container } from 'styled-bootstrap-grid'

const ChangePassword = () => {
  const history = useHistory()
  const { addToast } = useToast()
  const [isLoading, setLoading] = useState(false)

  const [newPassword, setNewPassword] = useState({
    value: '',
    isValid: false,
    touched: false
  })

  const [oldPassword, setOldPassword] = useState({
    value: '',
    isValid: true,
    touched: false
  })

  const { isOnline } = useContext(ConnectivityContext)

  function handleBack() {
    history.push('/perfil')
  }

  function changePassword() {
    setLoading(true)
    axios
      .get('/beneficiary/getdata')
      .then(result => {
        axios
          .post('/user/changepassword', {
            email: result.data.email.emailAddress,
            password: newPassword.value,
            token: oldPassword.value
          })
          .then(() =>
            ModalMessage({
              title: 'Senha alterada!',
              message: '',
              buttonText: 'OK!'
            })
          )
          .catch(err => {
            let errorDescription = 'Um erro ocorreu. Tente novamente.'
            let errorTitle = 'Falha na requisição.'

            if (err.data && err.status === 400) {
              errorDescription = err.data.Validate[0]
              errorTitle = 'Ops!'
            }

            addToast({
              type: 'error',
              title: errorTitle,
              description: errorDescription
            })
          })
          .finally(() => setLoading(false))
      })
      .catch(() => {
        addToast({
          description: 'Um erro ocorreu.',
          type: 'error',
          title: 'Falha na requisição.'
        })
        setLoading(false)
      })
  }

  return (
    <>
      <s.Page>
        <div>
          <s.Header>
            <Container>
              <BackIcon
                width={30}
                height={30}
                fill="gray"
                onClick={() => (isOnline ? handleBack() : null)}
              />
            </Container>
          </s.Header>

          <OfflineBar />

          <s.Content>
            <Text type={'h2_semibold'}>Digite sua nova senha</Text>

            <s.Message type={'font5'}>
              Por motivos de segurança sua nova senha não pode ser igual à
              anterior.
            </s.Message>

            <s.InputContainer>
              <Input
                type="password"
                label="Senha atual"
                value={oldPassword.value}
                alwaysShowMask={false}
                onChange={({ target }) =>
                  setOldPassword({ ...oldPassword, value: target.value })
                }
                onBlur={() => {
                  setOldPassword({
                    ...oldPassword,
                    touched: true
                  })
                }}
              />

              <Input
                type="password"
                label="Nova senha"
                value={newPassword.value}
                alwaysShowMask={false}
                onChange={({ target }) =>
                  setNewPassword({ ...newPassword, value: target.value })
                }
                onBlur={() => {
                  setNewPassword({
                    ...newPassword,
                    isValid: validatePassword(newPassword.value),
                    touched: true
                  })
                }}
                hasError={!newPassword.isValid && newPassword.touched}
                errorMessage="A senha deve conter os requisitos necessários."
              />

              <Text type="font7" color="gray2">
                No mínimo 10 caracteres, sendo:
              </Text>
              <Text type="font7" color="gray2">
                • Caractere alfabético maiúsculo e minúsculo
              </Text>
              <Text type="font7" color="gray2">
                • Caractere numérico
              </Text>
              <Text type="font7" color="gray2">
                • Caractere especial
              </Text>
            </s.InputContainer>
          </s.Content>
        </div>

        <s.Footer>
          <Container>
            <s.Button
              color="green"
              disabled={!newPassword.isValid || !isOnline}
              isLoading={isLoading}
              onClick={changePassword}
            >
              Salvar
            </s.Button>
          </Container>
        </s.Footer>
      </s.Page>
    </>
  )
}

export default ChangePassword
