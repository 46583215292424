import React, { useContext, useState } from 'react'

import Text from 'components/Text'
import ButtonNext from 'components/ButtonNext'
import TextArea from 'components/TextArea'
import IconExporter from 'components/IconExporter'

import { CreateAttendanceContext } from 'hooks/CreateAttendanceContext'

import * as s from '../styles'
import { useLocation } from 'react-router-dom'

const Symptoms = () => {
  const location = useLocation()

  const [buttonDisabled, setButtonDisabled] = useState(true)
  const { setCurrentStep, formData, setFormData } = useContext(
    CreateAttendanceContext
  )

  return (
    <s.Page>
      <s.Header>
        <IconExporter
          width={30}
          height={30}
          name="arrowleft"
          fill="gray"
          onClick={() => {
            setCurrentStep(null)
          }}
        />
      </s.Header>
      <s.Content>
        <Text type="h2_semibold">Descreva os sintomas que está sentindo</Text>
        <br />
        <br />
        <s.SymptomsTextarea>
          <TextArea
            onChange={({ target }) => {
              setFormData({
                ...formData,
                reason: target.value
              })
              setButtonDisabled(target.value.length === 0)
            }}
          />
        </s.SymptomsTextarea>
        <s.ContainerButtonNext>
          <ButtonNext
            disabled={buttonDisabled}
            onClick={() => {
              setCurrentStep('DATE_HOUR')
            }}
          />
        </s.ContainerButtonNext>
      </s.Content>
    </s.Page>
  )
}

export default Symptoms
