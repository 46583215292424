import styled from 'styled-components'

import Icon from 'components/IconExporter'
import Text from 'components/Text'

export const Prescriptions = styled.div`
  margin-top: 32px;
`

export const Container = styled.div`
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 1px 6px 0 ${({ theme }) => theme.colors.gray40};
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: space-between;
  margin-top: 16px;
  padding: 16px;
`

export const Prescription = styled(Text)``

export const ArrowIcon = styled(Icon).attrs(() => ({
  color: 'gray'
}))`
  height: 16px;
  transform: rotate(270deg);
  width: 16px;
`
