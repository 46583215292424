import React, { createContext, useState } from 'react'

export enum STEPS_ENUM {
  INFORM_CHECK_CAMERA = 'INFORM_CHECK_CAMERA',
  CHECK_CAMERA = 'CHECK_CAMERA',
  INFORM_CHECK_MICROPHONE = 'INFORM_CHECK_MICROPHONE',
  CHECK_MICROPHONE = 'CHECK_MICROPHONE',
  CHECKLIST = 'CHECKLIST'
}

type STEP = keyof typeof STEPS_ENUM

interface ContextData {
  currentStep: STEP
  setCurrentStep: (step: STEP) => void
}

const Context = createContext<ContextData>({} as ContextData)

const BeforeAttendanceProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<STEP>('INFORM_CHECK_CAMERA')

  return (
    <Context.Provider
      value={{
        currentStep,
        setCurrentStep
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Context, BeforeAttendanceProvider }
