import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'
import Text from 'components/Text'
import { themeColors } from 'styles/theme'

export const Page = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: flex-start;
  margin-bottom: 32px;
`

export const Title = styled(Text)`
  margin-bottom: 32px;
  text-align: center;
`

export const Image = styled.div`
  margin: 0 auto 1em;
  text-align: center;
  max-width: 150px;
`

export const Message = styled(Text)`
  font-weight: 400;
  margin: 1em 0;
  text-align: center;
  strong {
    color: ${themeColors.red}
  }
`
export const Subtitle = styled(Text)`
  font-weight: bold;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 32px;
  button {
    width: 100%;
  }
`
export const Item = styled.div`
  width: 100%;
  padding: 0.5em 0;
  justify-content: flex-start;
  display: flex;
  align-items: center;

  label {
    margin-right: 1em;
    &:last-of-type {
      margin-right: 0;
    }
  }
`