import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'

export const Page = styled.div`
  height: 100vh;
  width: 100vw;
`

export const Header = styled(Container)`
  background: ${({ theme }) => theme.colors.green};
  width: 100%;
  height: 58%;
  position: relative;
`

export const HeaderDescriptionContainer = styled.div`
  position: absolute;
  bottom: 40px;
`

export const ContainerTop = styled.div`
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 42%;

  padding-top: 60px;
  padding-bottom: 30px;
`

export const FooterContainer = styled.div`
  text-align: right;
`

export const ContainerPhoneNumberInput = styled.div`
  display: flex;
`

export const Ddi = styled.select`
  border: none;
  border-bottom: 1px solid #ccc;
  width: 90px;

  outline: none;
`
