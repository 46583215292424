import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'
import { ReactComponent as DefaultImage } from 'assets/images/appointment-sorry.svg'
import { useQuery } from 'hooks/useQuery'

import * as s from './styles'
import ModalMessage from 'components/ModalMessage'


const AppointmentSorryInfo = () => {
  const history = useHistory()
  const query = useQuery()

  const isModalOpen = !!query.get('isModalOpen')

  useEffect(() => {
    if (isModalOpen) {
      ModalMessage({
        title: 'Sua mensagem foi enviada',
        message: '',
        buttonText: 'Fechar'
      })
    }
  }, [isModalOpen])

  function handleBack() {
    history.push('/dashboard')
  }

  function handleDocwayPage() {
    window.open('https://docway.com.br/', '_blank')
  }
  
  return (
    <>
      <s.Page>
        <s.Header>
          <BackIcon
            width={30}
            height={30}
            fill="gray"
            onClick={() => handleBack()}
          />
        </s.Header>

        <s.Content>

          <s.ImageContainer>
            <DefaultImage />
          </s.ImageContainer>

          <s.Subtitle>
            Pedimos desculpas pelas tentativas frustadas de atendimento
          </s.Subtitle>

          <s.Info>
            Nosso time vai entrar em contato com voce para entender o que está acontecendo e te ajudar no que for necessário.
            <br></br>
            Ligaremos em breve do número
            <br></br>
            11 4200-0141, tudo bem?
          </s.Info>

        </s.Content>

        <s.FooterContainer>
          <s.ButtonStyled
            color="green"
            onClick={() => handleDocwayPage()}
          >
            Conhecer mais sobre a Docway
          </s.ButtonStyled>
        </s.FooterContainer>

      </s.Page>
    </>
  )
}

export default AppointmentSorryInfo
