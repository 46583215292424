import React from 'react'

import * as s from './styles'

type CheckboxProps = {
  className?: string
}

const Checkbox = ({
  className,
  ...rest
}: CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <s.Container className={className}>
      <input type="checkbox" {...rest} />
      <span />
    </s.Container>
  )
}

export default Checkbox
