import React from 'react'
import { themeColors } from 'styles/theme'

import * as s from './styles'

interface IProps {
  color?: keyof typeof themeColors
}

const EllipsisLoading = ({ color = 'gray', ...rest }: IProps) => (
  <s.Container color={color} {...rest}>
    <div />
    <div />
    <div />
  </s.Container>
)

export default EllipsisLoading
