import styled from 'styled-components'
import { Container as ContainerBTS } from 'styled-bootstrap-grid'

export const Header = styled(ContainerBTS)`
  height: 60px;
  background-color: ${({ theme }) => theme.colors.green};

  display: flex;
  align-items: center;
`

export const Container = styled(ContainerBTS)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 40%;
  justify-content: flex-end;
`

export const ContainerInputs = styled.div`
  margin-top: 32px;
  input {
    outline: none;
    width: 70px;
    height: 70px;
    text-align: center;
    margin-right: 12px;
    border-radius: 12px;
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.gray};
    color: ${({ theme }) => theme.colors.gray};

    ${({ theme }) => theme.fontTypes.p2_regular};
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-top: 200px;
`

export const LoadingContainer = styled.div`
  display: flex;
  align-items: flex-end;
`
