import React, { createContext, FunctionComponent } from 'react'
import { useConnectivity } from './useConnectivity'

export const ConnectivityContext = createContext<ConnectivityContextProps>({
  isOnline: true,
  checkStatus: () => null
})

export const ConnectivityProvider: FunctionComponent = ({ children }) => {
  const { isOnline, checkStatus } = useConnectivity()

  return (
    <ConnectivityContext.Provider
      value={{
        isOnline,
        checkStatus
      }}
    >
      {children}
    </ConnectivityContext.Provider>
  )
}

interface ConnectivityContextProps {
  isOnline: boolean
  checkStatus(): void
}
