import React from 'react'
import { ThemeProvider } from 'styled-components'
import { GridThemeProvider } from 'styled-bootstrap-grid'
import { BrowserRouter } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import { AccountInfoProvider } from 'hooks/AccountValidation'
import { CreateAttendanceProvider } from 'hooks/CreateAttendanceContext'
import { BeforeAttendanceProvider } from 'hooks/BeforeAttendanceContext'
import { VideoProvider } from 'hooks/LocalTracksContext'
import { ToastProvider } from 'hooks/toast'
import theme from 'styles/theme'
import { ConnectivityProvider } from 'hooks/ConnectivityContext'
import { RestfulProvider } from 'hooks/RestfulContext'

const AppProviders = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GridThemeProvider gridTheme={theme.grid}>
      <ToastProvider>
        <ConnectivityProvider>
          <AccountInfoProvider>
            <VideoProvider>
              <BeforeAttendanceProvider>
                <CreateAttendanceProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <RestfulProvider>
                      <BrowserRouter>{children}</BrowserRouter>
                    </RestfulProvider>
                  </MuiPickersUtilsProvider>
                </CreateAttendanceProvider>
              </BeforeAttendanceProvider>
            </VideoProvider>
          </AccountInfoProvider>
        </ConnectivityProvider>
      </ToastProvider>
    </GridThemeProvider>
  </ThemeProvider>
)

export default AppProviders
