import styled from 'styled-components'

export const Buttons = styled.div`
  display: flex;
`

const Button = styled.button`
  background-color: ${props => props.theme.colors.white};
  border: 0;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  padding: 16px;
  width: 50%;
`

export const Cancel = styled(Button)`
  border-bottom-left-radius: 5px;
  border-top: 2px solid ${props => props.theme.colors.grayLight};
  color: ${props => props.theme.colors.gray};
`

export const Confirm = styled(Button)`
  border-bottom-right-radius: 5px;
  border-left: 2px solid ${props => props.theme.colors.grayLight};
  border-top: 2px solid ${props => props.theme.colors.grayLight};
  color: ${props => props.theme.colors.green};
`
