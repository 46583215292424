import styled from 'styled-components'
import * as TextStyles from 'components/Text/styles'
import Button from 'components/Button'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 12px 16px 40px;
`

export const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const CloseIconButton = styled.div`
  height: 32px;
  width: 32px;
  display: grid;
  place-items: center;
`

export const Grades = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

interface GradeProps {
  selected: boolean
}

export const Grade = styled.div<GradeProps>`
  height: 40px;
  width: 45px;
  border-radius: 4px;
  border: solid 1px
    ${({ selected, theme }) =>
      selected ? theme.colors.green : theme.colors.gray40};

  display: grid;
  place-items: center;
  margin-top: 8px;
  margin-right: 8px;

  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.green : 'initial'};

  ${TextStyles.TextStyle} {
    color: ${({ selected, theme }) => selected && theme.colors.white};
  }

  :active {
    background-color: ${({ theme }) => theme.colors.green};
  }
`

export const TextAreaContainer = styled.div`
  height: 100px;
  margin-top: 20px;
`

export const ButtonStyled = styled(Button)`
  margin-top: 20px;
  width: 100%;
`
