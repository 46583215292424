import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Text from 'components/Text'
import IconExporter from 'components/IconExporter'
import TextArea from 'components/TextArea'

import { useToast } from 'hooks/toast'
import axiosInstance from 'services/api'

import * as s from './styles'

const AttendanceFeedback = ({ onCloseModal }) => {
  const [grade, setGrade] = useState(null)
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { addToast } = useToast()

  async function handleFeedbackRequest() {
    const params = new URLSearchParams(history.location.search)

    setLoading(true)
    try {
      await axiosInstance.post('/appointment/survey', {
        appointmentId: Number(params.get('appointmentId')),
        answer: String(grade),
        description: note
      })
      addToast({
        title: 'Avaliação enviada com sucesso.',
        description: 'Sua avaliação foi registrada, obrigado!',
        type: 'success'
      })
      onCloseModal(localStorage.setItem('surveyPatientHasBeenFilled', 'true'))
    } catch (err) {
      addToast({
        title: 'Falha ao enviar a avaliação.',
        description:
          'Não foi possível registrar sua avaliação. Tente novamente.',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  function renderGrades() {
    const grades = []
    for (let index = 0; index <= 10; index++) {
      grades.push(
        <s.Grade
          key={index}
          selected={grade === index}
          onClick={() => {
            setGrade(index)
          }}
        >
          <Text>{index}</Text>
        </s.Grade>
      )
    }
    return grades
  }
  return (
    <s.Container>
      <s.CloseIconContainer>
        <s.CloseIconButton onClick={onCloseModal}>
          <IconExporter name="close" width={14} height={14} fill="gray" />
        </s.CloseIconButton>
      </s.CloseIconContainer>
      <Text type="font5">
        <strong>Em uma escala de 0 a 10</strong>, o quanto você recomendaria o
        atendimento médico por vídeo para um amigo ou colega?
      </Text>

      <s.Grades>{renderGrades()}</s.Grades>

      {grade !== null && (
        <>
          <s.TextAreaContainer>
            <TextArea
              placeholder="Descreva o principal motivo da sua nota"
              value={note}
              onChange={({ target }) => {
                setNote(target.value)
              }}
            />
          </s.TextAreaContainer>

          <s.ButtonStyled
            isLoading={loading}
            color="green"
            disabled={grade === null || grade === undefined}
            onClick={handleFeedbackRequest}
          >
            Enviar
          </s.ButtonStyled>
        </>
      )}
    </s.Container>
  )
}

export default AttendanceFeedback
