import styled from 'styled-components'

export const Container = styled.div`
  margin: 32px 0 24px;
`

export const MonthPicker = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  opacity: 0.6;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  padding: 0 16px 0 12px;

  input {
    border: none;
    width: 100%;
    ${({ theme }) => theme.fontTypes.p2_regular};
  }
`
