import React from 'react'
import { Carousel } from 'react-responsive-carousel'

import Text from 'components/Text'

import image1 from 'assets/carousel/image1.png'
import image2 from 'assets/carousel/image2.png'
import image3 from 'assets/carousel/image3.png'
import Logo from 'assets/logo_negativo.svg'

import * as s from './styles'
import SpinnerLoading from 'components/SpinnerLoading'
import { useHistory } from 'react-router-dom'
import localStorage from 'helpers/localStorage'

const Welcome = () => {
  const history = useHistory()

  if (localStorage.getUserAuth().accessToken) {
    history.push(`/dashboard`)
    return <SpinnerLoading />
  }

  return (
    <s.Page>
      <s.Logo src={Logo} />
      <Carousel
        autoPlay
        renderArrowNext={() => null}
        renderArrowPrev={() => null}
        showThumbs={false}
        showStatus={false}
        infiniteLoop
      >
        <div>
          <img
            alt="mulher jovem sorrindo olhando para o celular"
            src={image1}
          />
          <s.CarouselSubtitle>
            As melhores soluções para você que já é um beneficiário Docway ou
            parceiro.
          </s.CarouselSubtitle>
        </div>
        <div>
          <img alt="rapaz gesticulando em direção a camera" src={image2} />
          <s.CarouselSubtitle>
            As melhores soluções para transformar sua relação com a saúde.
          </s.CarouselSubtitle>
        </div>
        <div>
          <img
            alt="mulher jovem sorrindo olhando para o celular"
            src={image3}
          />
          <s.CarouselSubtitle>
            Seja atendido por um médico sem nem precisar levantar do sofá!
          </s.CarouselSubtitle>
        </div>
      </Carousel>
      <s.Container>
        <s.Button
          color="white"
          onClick={() => {
            history.push('/login')
          }}
        >
          <Text color="white">Entrar</Text>
        </s.Button>
        <s.RegisterScreenLink type="font3" textAlign="center" color="white">
          Ainda não é cadastrado?{' '}
          <span
            onClick={() => {
              history.push('/cadastro')
            }}
          >
            Se cadastrar no aplicativo
          </span>
        </s.RegisterScreenLink>
      </s.Container>
    </s.Page>
  )
}

export default Welcome
