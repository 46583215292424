import React, { useState, useContext } from 'react'

import Text from 'components/Text'
import PhoneNumber from 'components/PhoneNumber'
import ButtonNext from 'components/ButtonNext'

import { Context } from 'hooks/AccountValidation'

import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'

import * as s from './styles'

const PhoneNumberRegistration = () => {
  const { setCurrentStep, setFormData, formData } = useContext(Context)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [ddi, setDdi] = useState('')

  return (
    <s.Page>
      <s.Header>
        <s.ContainerTop>
          <BackIcon
            width={30}
            height={30}
            fill="white"
            onClick={() => {
              setCurrentStep(null)
            }}
          />
        </s.ContainerTop>
        <s.HeaderDescriptionContainer>
          <Text color="white" type="h2_semibold">
            Insira o seu telefone
          </Text>
        </s.HeaderDescriptionContainer>
      </s.Header>
      <s.Content>
        <PhoneNumber
          ddi={ddi}
          onChangeDdi={setDdi}
          phoneNumber={phoneNumber}
          onChangePhoneNumber={setPhoneNumber}
        />
        <s.FooterContainer>
          <ButtonNext
            disabled={phoneNumber.length < 10}
            onClick={() => {
              setFormData({
                ...formData,
                phoneNumber: `${phoneNumber}`,
                ddi: `${ddi}`
              })
              setCurrentStep('EMAIL') // setCurrentStep('NAME_QUESTION')
            }}
          />
        </s.FooterContainer>
      </s.Content>
    </s.Page>
  )
}

export default PhoneNumberRegistration
