import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Text from 'components/Text'
import IconExporter from 'components/IconExporter'
import TextArea from 'components/TextArea'
import ModalConfirm from 'components/ModalConfirm'

import { useGet, useMutate } from 'restful-react'

import * as s from './styles'
import { CreateAttendanceContext } from 'hooks/CreateAttendanceContext'
import { useQuery } from 'hooks/useQuery'
import { useToast } from 'hooks/toast'

const AttendanceUnsuccessful = ({ onCloseModal }) => {
  const [reason, setReason] = useState('')
  const [
    isRescheduleGreaterThanLimit,
    setIsRescheduleGreaterThanLimit
  ] = useState(false)
  const { addToast } = useToast()
  const history = useHistory()
  const { setPartnerId, formData, setFormData } = useContext(
    CreateAttendanceContext
  )
  const query = useQuery()

  const appointmentId = query.get('appointmentId')

  const { loading: loadingSystemVariable, data: limitOfReschedules } = useGet(
    `systemvariables/unsuccessfulreschedulelimit`
  )

  const {
    loading: loadingUpdate,
    mutate: updateUnsuccessfulAppointment
  } = useMutate('PATCH', `appointment/${appointmentId}/unsuccessful`)

  const { loading: loadingAppointment, data } = useGet(
    `appointment/${appointmentId}`
  )

  const loading = loadingUpdate || loadingAppointment || loadingSystemVariable

  useEffect(() => {
    if (data && limitOfReschedules) {
      setIsRescheduleGreaterThanLimit(
        data.numberOfReschedule > limitOfReschedules
      )
    }
  }, [limitOfReschedules, data])

  const handleAppointmentUnsuccessfulRequest = async () => {
    try {
      await updateUnsuccessfulAppointment({
        reason
      })
    } catch {
      addToast({
        title: 'Falha ao enviar a mensagem',
        description: 'Tente novamente.',
        type: 'error'
      })

      return
    }

    onCloseModal()
    if (isRescheduleGreaterThanLimit) {
      history.push(`/atendimento-info?isModalOpen=true`)
    } else {
      ModalConfirm({
        title: 'Sua mensagem foi enviada',
        message: 'O que deseja fazer agora?',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Reagendar',
        handleConfirm: handleReschedule
      })
    }
  }

  const handleReschedule = async () => {
    setPartnerId(data.companyClient.id)
    setFormData({ ...formData, reason: data.reason })
    history.push(
      `/criar-atendimento?fromUnsuccessful=true&originAppointmentId=${appointmentId}`
    )
  }

  const handleSkip = async () => {
    history.push(`/atendimento-info`)
  }

  return (
    <s.Container>
      <s.CloseIconContainer>
        <s.CloseIconButton onClick={onCloseModal}>
          <IconExporter name="close" width={14} height={14} fill="gray" />
        </s.CloseIconButton>
      </s.CloseIconContainer>
      <Text type="font13">Que pena, sentimos muito!</Text>

      <s.TextAreaContainer>
        <TextArea
          placeholder="Descreva o que aconteceu (opcional)"
          value={reason}
          onChange={({ target }) => {
            setReason(target.value)
          }}
        />
      </s.TextAreaContainer>

      <s.ButtonStyled
        isLoading={loading}
        color="green"
        onClick={handleAppointmentUnsuccessfulRequest}
      >
        Enviar
      </s.ButtonStyled>

      {!loading &&
        (isRescheduleGreaterThanLimit ? (
          <s.RescheduleContainer>
            <Text type="font5" color="gray" onClick={handleSkip}>
              Pular
            </Text>
          </s.RescheduleContainer>
        ) : (
          <s.RescheduleContainer>
            <Text type="font5" color="gray" onClick={handleReschedule}>
              Pular para o reagendamento
            </Text>
          </s.RescheduleContainer>
        ))}
    </s.Container>
  )
}

export default AttendanceUnsuccessful
