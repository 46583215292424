import React, { useState, useEffect, useContext, useCallback } from 'react'
import {
  connect,
  Room as IRoom,
  LocalAudioTrack,
  LocalVideoTrack,
  Participant as IParticipant
} from 'twilio-video'

import IconExporter from 'components/IconExporter'
import Text from 'components/Text'
import EllipsisLoading from 'components/EllipsisLoading'

import { handleMediaErrors } from './utils'
import * as s from './styles'
import { VideoContext } from 'hooks/LocalTracksContext'
import { useGet } from 'restful-react'
import { Doctor } from 'types/Appointment'

interface IProps {
  token: string
  name: string
  appointmentId: number
  onLocalUserDisconnected: () => void
}

function redirectToFAQ() {
  window.open('https://docwayhelp.zendesk.com/hc/pt-br', '_blank')
}

const Room = ({
  token,
  name,
  onLocalUserDisconnected,
  appointmentId
}: IProps) => {
  const [room, setRoom] = useState<IRoom | null>(null)
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(true)
  const [isVideoOn, setIsVideoOn] = useState(true)
  const [isErrorBoxVisible, setIsErrorBoxVisible] = useState(true)
  const [hasErrorWhileConnecting, setHasErrorWhileConnecting] = useState(false)
  const [localTracksError, setLocalTracksErrors] = useState(null)
  const [waitingConnection, setWaitingConnection] = useState(true)

  const [doctor, setDoctor] = useState<Doctor>(null)

  const { localTracks, createAudioAndVideoTracks } = useContext(VideoContext)

  const [localAudioTrack, setLocalAudioTrack] = useState<LocalAudioTrack>(null)
  const [localVideoTrack, setLocalVideoTrack] = useState<LocalVideoTrack>(null)

  const [participants, setParticipants] = useState([])

  const { data, refetch } = useGet(`/Appointment/${appointmentId}`)

  const verifyPatientFilledSurvey = useCallback(() => {
    data?.surveyPatientHasBeenFilled
      ? localStorage.setItem('surveyPatientHasBeenFilled', 'true')
      : localStorage.setItem('surveyPatientHasBeenFilled', 'false')
  }, [data])

  const toggleErrorBox = () => {
    setIsErrorBoxVisible(prev => !prev)
  }

  const toggleVoice = () => {
    if (isMicrophoneOn) {
      room.localParticipant.audioTracks.forEach(audioTrack => {
        audioTrack.track.disable()
      })
      setIsMicrophoneOn(prev => !prev)
    } else {
      room.localParticipant.audioTracks.forEach(audioTrack => {
        audioTrack.track.enable()
      })
      setIsMicrophoneOn(prev => !prev)
    }
  }

  const toggleVideo = () => {
    if (isVideoOn) {
      room.localParticipant.videoTracks.forEach(videoTrack => {
        videoTrack.track.disable()
      })
      setIsVideoOn(prev => !prev)
    } else {
      room.localParticipant.videoTracks.forEach(videoTrack => {
        videoTrack.track.enable()
      })
      setIsVideoOn(prev => !prev)
    }
  }

  useEffect(() => {
    verifyPatientFilledSurvey()
  }, [data])
  useEffect(() => {
    if (data && data.doctor) {
      setDoctor(data.doctor)
    }
  }, [data])

  useEffect(() => {
    createAudioAndVideoTracks().catch(error => {
      setLocalTracksErrors(prev => ({
        ...prev,
        ...handleMediaErrors(error)
      }))
      setHasErrorWhileConnecting(true)
      setWaitingConnection(false)
    })
  }, [createAudioAndVideoTracks])

  useEffect(() => {
    if (!localAudioTrack) {
      const audioTrack = localTracks.find(
        track => track.kind === 'audio'
      ) as LocalAudioTrack

      if (audioTrack) {
        if (audioTrack.isStopped) {
          audioTrack.restart()
        }
        setLocalAudioTrack(audioTrack)
      }
    }

    if (!localVideoTrack) {
      const videoTrack = localTracks.find(track =>
        track.name.includes('camera')
      ) as LocalVideoTrack

      if (videoTrack) {
        if (videoTrack.isStopped) {
          videoTrack.restart()
        }
        setLocalVideoTrack(videoTrack)
      }
    }
  }, [localTracks, localAudioTrack, localVideoTrack])

  useEffect(() => {
    if (!localAudioTrack || !localVideoTrack) return
    let delayUserDisconnetion = null
    const participantConnected = (participant: IParticipant) => {
      setParticipants(prevParticipants => [...prevParticipants, participant])

      refetch()

      clearTimeout(delayUserDisconnetion)
    }

    const participantDisconnected = (participant: IParticipant) => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      )
      delayUserDisconnetion = setTimeout(onLocalUserDisconnected, 5000)
    }

    setWaitingConnection(true)
    setHasErrorWhileConnecting(false)

    async function twillioConnection() {
      connect(token, {
        name: name,
        tracks: [localAudioTrack, localVideoTrack] || undefined
      })
        .then(room => {
          setRoom(room)
          room.on('participantConnected', participantConnected)
          room.on('participantDisconnected', participantDisconnected)
          room.once('disconnected', () =>
            room.participants.forEach(participantDisconnected)
          )
          room.participants.forEach(participantConnected)
          setWaitingConnection(false)
        })
        .catch(error => {
          setLocalTracksErrors(prev => ({
            ...prev,
            ...handleMediaErrors(error)
          }))
          setHasErrorWhileConnecting(true)
          setWaitingConnection(false)
        })
    }

    twillioConnection()

    return () => {
      setRoom((currentRoom: IRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach((publication: any) => {
            publication.track.stop()
          })
          currentRoom.disconnect()
          return null
        } else {
          return currentRoom
        }
      })
    }
  }, [token, name, localAudioTrack, localVideoTrack, onLocalUserDisconnected])

  const remoteParticipants = participants.map(participant => (
    <s.FullVideo
      key={participant.sid}
      participant={participant}
      doctor={doctor}
    />
  ))

  function renderContent() {
    if (room && !hasErrorWhileConnecting) {
      return (
        <s.Content>
          <s.MiniParticipant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            doctor={doctor}
            isMiniVideo
          />

          {remoteParticipants.length === 0 ? (
            <s.LoadingContainer>
              <Text textAlign="center">
                <svg
                  width="31"
                  height="20"
                  viewBox="0 0 31 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.8245 1.6963C29.4165 1.43775 28.9077 1.41621 28.4805 1.6394L22.9664 4.52347V4.26743C22.9664 1.91059 21.1148 0 18.8308 0H4.63555C2.35155 0 0.5 1.91059 0.5 4.26743V15.7326C0.5 18.0894 2.35155 20 4.63555 20H18.8481C21.1321 20 22.9836 18.0894 22.9836 15.7326V15.4765L28.4977 18.3606C28.9252 18.5844 29.4347 18.563 29.8432 18.304C30.2516 18.0451 30.5004 17.5857 30.5 17.091V2.90896C30.4963 2.41054 30.24 1.95049 29.8245 1.6963ZM21 16.4095C21 17.2879 20.3272 18 19.4972 18H4.00284C3.17285 18 2.5 17.2879 2.5 16.4095V3.59046C2.5 2.71207 3.17285 2 4.00284 2H19.4972C20.3272 2 21 2.71207 21 3.59046V16.4095ZM23 12.9912L28.625 16.25V3.75L23 7.00885V12.9912Z"
                    fill="#334155"
                  />
                </svg>
              </Text>
              <Text
                textAlign="center"
                style={{ color: '#334155', paddingTop: '1rem' }}
              >
                O profissional está se preparando para atende-lo e em breve
                entrará na sala.
              </Text>
              <Text
                textAlign="center"
                style={{ color: '#334155', paddingTop: '1rem' }}
              >
                Não desconecte-se, mantenha-se na sala até o atendimento ser
                iniciado.
              </Text>
              <br />
              <EllipsisLoading color="gray" />
            </s.LoadingContainer>
          ) : (
            remoteParticipants
          )}

          <s.Controls>
            <s.SmallerButton>
              <IconExporter
                name={isMicrophoneOn ? 'microphone' : 'microphoneoff'}
                width={28}
                height={28}
                color="gray"
                onClick={toggleVoice}
              />
            </s.SmallerButton>

            <s.HangoutButton
              onClick={() => {
                room.disconnect()
                onLocalUserDisconnected()
              }}
            >
              <IconExporter name="phone" width={30} height={30} color="white" />
            </s.HangoutButton>

            <s.SmallerButton>
              <IconExporter
                name={isVideoOn ? 'video' : 'videooff'}
                width={28}
                height={28}
                color="gray"
                onClick={toggleVideo}
              />
            </s.SmallerButton>
          </s.Controls>
        </s.Content>
      )
    } else if (hasErrorWhileConnecting) {
      return (
        <s.ReconnectiveContainer>
          <s.Title>Ops! Parece que houve um problema de conexão :(</s.Title>
          <s.SubTitle>
            Achamos um problema que impede a conexão para o atendimento por
            vídeo. Por favor, tente realizar a conexão novamente.
          </s.SubTitle>
          <s.ReconnectiveButton onClick={() => window.location.reload()}>
            TENTAR CONECTAR NOVAMENTE
          </s.ReconnectiveButton>
        </s.ReconnectiveContainer>
      )
    }
    return (
      <s.ErrorBox visible={isErrorBoxVisible}>
        <IconExporter
          name="close"
          width={30}
          height={30}
          fill="gray"
          onClick={() => {
            toggleErrorBox()
          }}
        />
        <Text textAlign="center" type="h3_semibold">
          {localTracksError.title}
        </Text>
        <br />
        <Text type="p2_regular" textAlign="center">
          {localTracksError.message}
        </Text>
        <br />
        <Text color="gray2" type="font8" textAlign="center">
          Ficou com alguma dúvida?{' '}
          <u onClick={redirectToFAQ}>Acesse nosso FAQ</u>
        </Text>
      </s.ErrorBox>
    )
  }

  return (
    <s.Container>
      {waitingConnection ? (
        <s.LoadingContainer>
          <EllipsisLoading color="gray" />
        </s.LoadingContainer>
      ) : (
        renderContent()
      )}
    </s.Container>
  )
}

export default Room
