const TAG = '@docway-beneficiary/'

export interface UserAuth {
  accessToken: string
  refreshToken: string
  expiresIn: number
  tokenType: string
  userId?: string
}

export const getUserAuth = () => {
  const userAuth: UserAuth =
    JSON.parse(localStorage.getItem(`${TAG}userAuth`)) || []
  return userAuth
}

export const setUserAuth = (userAuth: UserAuth) => {
  let key: string
  const keys = Object.keys(userAuth)
  const newUserAuth = {}

  for (let i = 0; i < keys.length; i++) {
    key = keys[i]
    newUserAuth[key.charAt(0).toLowerCase() + key.slice(1)] = userAuth[key]
  }

  localStorage.setItem(`${TAG}userAuth`, JSON.stringify(newUserAuth))
}

export const removeUserAuth = () => {
  localStorage.removeItem(`${TAG}userAuth`)
}

export default {
  getUserAuth,
  setUserAuth,
  removeUserAuth
}
