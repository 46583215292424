import styled from 'styled-components'
import { Container } from 'styled-bootstrap-grid'
import ButtonDefault from 'components/Button'
import { themeColors, fontTypes } from 'styles/theme'
import { ReactComponent as PeopleIcon } from 'assets/icons/ic-people-white.svg'

export interface IStyledProps {
  borderColor?: keyof typeof themeColors
  backgroundColor?: keyof typeof themeColors
  type?: keyof typeof fontTypes
  color?: keyof typeof themeColors
}

export interface IImageProps {
  width: string
  height: string
  radious: string
  backgroundColor?: keyof typeof themeColors
}

export const Page = styled.div`
  width: 100vw;
  height: 100vh;
`
export const Header = styled(Container)`
  position: relative;
  top: 20px;
  height: 64px;
  overflow-x: auto;
  padding-left: 12px;
`

export const DependentsContainer = styled(Container)<IStyledProps>`
  display: flex;
  border-bottom: 1px solid
    ${({ theme, borderColor }) => theme.colors[borderColor]};
  height: 100px !important;
  overflow-x: auto;

  p {
    padding: 20px 0px 20px 0px;
  }

  div > p {
    position: relative;
    top: -15px;
    ${props => props.theme.fontTypes[props.type]}
  }
`
export const ImageContainer = styled.div<IImageProps>`
  width: ${props => props.width};
  height: ${props => props.width};
  margin: 0px 15px 0px 0px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
    border-radius: ${props => props.width};
  }
  div {
    width: 100%;
    height: 100%;
    border-radius: ${props => props.width};
    background-color: ${({ theme, backgroundColor }) =>
      theme.colors[backgroundColor]};
    align-items: center;
    justify-content: center;
  }
`
export const People = styled(PeopleIcon)`
  height: 50px;
  width: 50px;
`

export const ProfileImageContainer = styled(Container)<IStyledProps>`
  height: auto;

  div {
    display: flex;
  }

  p {
    padding: 20px 0px 20px 20px;
    color: ${props => props.theme.colors[props.color]};
  }
`

export const SubTitle = styled.div<IStyledProps>`
  p {
    padding: 20px 0px 20px 0px;
    color: ${props => props.theme.colors[props.color]};
  }
`

export const DataContainer = styled(Container)<IStyledProps>`
  div {
    margin-top: 55px;
  }

  input {
    background-color: ${({ theme, backgroundColor }) =>
      theme.colors[backgroundColor]};
  }
`
export const RadioContainer = styled.div<IStyledProps>`
  margin-top: 40px !important;

  p {
    ${props => props.theme.fontTypes[props.type]}
  }

  div {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    label {
      ${props => props.theme.fontTypes[props.type]}
    }

    input {
      width: 17px;
      height: 17px;
    }
  }
`

export const Footer = styled(Container)`
  position: absolute;
  margin: 30px auto 30px auto;
`

export const Button = styled(ButtonDefault)`
  width: 100%;
  margin: 12px 0px 12px;
`

export const PhoneNumberContainer = styled.div`
  div > div > div {
    margin: unset;
  }
`
