import React, { useEffect, useState } from 'react'

import Input from 'components/Input'
import countryCodes from './countryCodes.json'
import * as s from './styles'

interface IProps {
  ddi: string
  phoneNumber: string
  onChangeDdi: (ddi: string) => void
  onChangePhoneNumber: (phoneNumber: string) => void
}

const PhoneNumber = ({
  ddi,
  phoneNumber,
  onChangeDdi,
  onChangePhoneNumber
}: IProps) => {
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    if (!ddi) {
      onChangeDdi('+55')
    }
    if (ddi === '+55') {
      onChangePhoneNumber(
        phoneNumber
          .replace(/^(\d{2})(\d)/g, '($1) $2')
          .replace(/(\d{4,5})(\d{4})/g, '$1-$2')
      )
    } else {
      onChangePhoneNumber(phoneNumber.replace(/\D/g, ''))
    }
  }, [ddi, onChangeDdi, phoneNumber, onChangePhoneNumber])

  function allowNumbersOnly(e: React.KeyboardEvent) {
    const code = e.which ? e.which : e.keyCode
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault()
    }
    setTouched(true)
  }

  const hasError = phoneNumber.length === 0 && touched

  return (
    <s.Container hasError={hasError}>
      <label>Celular</label>
      <s.Input>
        <s.Ddi
          hasError={hasError}
          value={ddi}
          onChange={e => {
            onChangeDdi(e.target.value)
          }}
        >
          <option value="+55">BRA (+55)</option>
          <optgroup label="Outros países">
            {countryCodes.map(country => (
              <option
                key={`${country.name}-${country.code}`}
                value={country.code}
                label={`${country.name} (${country.code})`}
              />
            ))}
          </optgroup>
        </s.Ddi>
        <Input
          onKeyPress={allowNumbersOnly}
          value={phoneNumber}
          onChange={({ target }) => {
            let newValue = target.value
            if (ddi === '+55') {
              newValue = newValue
                .replace(/^(\d{2})(\d)/g, '($1) $2')
                .replace(/(\d{4,5})(\d{4})/g, '$1-$2')
            }
            onChangePhoneNumber(newValue)
          }}
          maxLength={15}
          hasError={hasError}
          errorMessage="Digite um telefone."
        />
      </s.Input>
    </s.Container>
  )
}

export default PhoneNumber
