import { ConnectivityContext } from 'hooks/ConnectivityContext'
import React, { useContext } from 'react'

import { Link, useHistory } from 'react-router-dom'

import * as s from './styles'

const MENU_ROUTE_PATHS = {
  dashboard: '/dashboard',
  atendimentos: '/historico',
  perfil: '/perfil'
}

const FooterMenu = () => {
  const { isOnline } = useContext(ConnectivityContext)

  const { location } = useHistory()

  function isPageActive(page) {
    return location.pathname.includes(page)
  }

  return (
    <s.Container>
      <Link to={isOnline ? MENU_ROUTE_PATHS.atendimentos : '#'}>
        <s.Icon
          name="historic"
          selected={isPageActive(MENU_ROUTE_PATHS.atendimentos)}
        />
      </Link>
      <Link to={isOnline ? MENU_ROUTE_PATHS.dashboard : '#'}>
        <s.Icon
          name="doctor"
          selected={isPageActive(MENU_ROUTE_PATHS.dashboard)}
        />
      </Link>
      <Link to={isOnline ? MENU_ROUTE_PATHS.perfil : '#'}>
        <s.Icon
          name="people"
          selected={isPageActive(MENU_ROUTE_PATHS.perfil)}
        />
      </Link>
    </s.Container>
  )
}

export default FooterMenu
