import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import * as s from './styles'
import { ReactComponent as BackIcon } from 'assets/icons/ic-backarrow.svg'
import SpinnerLoading from 'components/SpinnerLoading'

import Moment from 'moment'
import Prescriptions from './Prescriptions'
import Symptoms from './Symptoms'
import { Container } from 'styled-bootstrap-grid'
import { ConnectivityContext } from 'hooks/ConnectivityContext'
import OfflineBar from 'components/OfflineBar'
import { useGet } from 'restful-react'

const HistoricDetails = () => {
  const history = useHistory()
  const { id } = useParams() as any;

  const { isOnline } = useContext(ConnectivityContext)

  const { loading, data: appointment } = useGet(`appointment/${id}`)

  function getStatusTitle(status: number): string {
    switch (status) {
      case 8:
      case 10:
      case 11:
        return 'Cancelado'
      case 12:
        return 'Ausência'
      default:
        return 'Finalizado'
    }
  }

  return (
    <s.Page>
      <Container>
        <s.Header>
          <BackIcon
            width={30}
            height={30}
            fill="gray"
            onClick={() => (isOnline ? history.goBack() : null)}
          />
        </s.Header>
      </Container>

      <OfflineBar />

      {loading ? (
        <SpinnerLoading />
      ) : (
        <Container>
          <s.DoctorContainer>
            <s.DoctorStartContainer col>
              <s.DoctorName>
                {appointment.doctor?.firstName} {appointment.doctor?.lastName}
              </s.DoctorName>

              <s.DateAppointment type="font5">
                {Moment(appointment.dateAppointment).format('DD/MM/YYYY')} às{' '}
                {Moment(appointment.dateAppointment).format('HH:mm')}
              </s.DateAppointment>

              <s.PatientName type="font5">
                Para {appointment.requester.firstName}{' '}
                {appointment.requester.lastName}
              </s.PatientName>
            </s.DoctorStartContainer>

            <s.DoctorEndContainer col="auto">
              {appointment.doctor ? (
                <s.DoctorImage src={appointment.doctor?.profilePicture} />
              ) : null}

              <s.StatusContainer status={appointment.status}>
                <s.VideoIcon name="video" />
                <s.Status type="font2">
                  {getStatusTitle(appointment.status)}
                </s.Status>
              </s.StatusContainer>
            </s.DoctorEndContainer>
          </s.DoctorContainer>

          <s.BrazilTimezoneMessage type="p3_regular">
            (Considerando o horário oficial de Brasília)
          </s.BrazilTimezoneMessage>

          <Prescriptions prescriptions={appointment.prescriptions} />

          <s.Divider />

          <s.Specialty type="font13">
            Atendimento {appointment.specialty.name}
          </s.Specialty>
          <s.PartnerName type="font2">
            por {appointment.companyClient.name}
          </s.PartnerName>

          <Symptoms symptoms={appointment.symptoms} />
        </Container>
      )}
    </s.Page>
  )
}

export default HistoricDetails
