import styled from 'styled-components'

import Text from 'components/Text'

export const SymptomsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
`

export const Symptom = styled(Text)`
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 8px;
  margin: 0 8px 8px 0;
  padding: 8px 12px;
`
