import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
`

export const Header = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`

export const Content = styled.div`
  height: calc(100vh - 64px);
  background-color: rgba(112, 112, 112, 0.2);

  display: grid;
  place-items: center;
`

export const Card = styled.div`
  border-radius: 12px;
  background-color: white;
  margin: 0px 16px;
`

export const CardContainer = styled.div`
  padding: 24px 16px;
`

export const CardFooter = styled.div`
  border-top: 1px solid #ccc;
  height: 48px;
  display: grid;
  place-items: center;
`
